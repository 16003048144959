import { APICore } from 'helpers/api/apiCore'
import { MatriculaReq, MeusDadosReq } from 'models/meusDados/types'
import { removeMaskCEP } from 'util/masks'

const api = new APICore()

export function getPerfis() {
  return api.get(`Generic/perfis`, null)
}

export function getGeneros() {
  return api.get(`Generic/generos`, null)
}

export function getOrgaosEmissores() {
  return api.get(`Generic/orgaos-emissores`, null)
}

export function getEscolaridades() {
  return api.get(`Generic/escolaridades`, null)
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getCidades(siglaEstado: string) {
  return api.get(`Generic/cidades`, {
    siglaEstado
  })
}

export function getEstadosCivis() {
  return api.get(`Generic/estados-civis`, null)
}

export function getTiposConta() {
  return api.get(`Generic/tipos-conta`, null)
}

export function getBancos() {
  return api.get(`Generic/bancos-dados-bancarios`, null)
}

export function getConvenios() {
  return api.get(`Generic/convenios`, null)
}

export function getProfissoes() {
  return api.get(`Generic/profissoes`, null)
}

export function getDadosEndereco(cep: string) {
  const cepFormatted = removeMaskCEP(cep)
  return api.get(`Generic/informacoes-endereco`, { cep: cepFormatted })
}

export function getInformacoes() {
  return api.get(`CadastroCliente/meus-dados`, null)
}

export function salvarInformacoes(params: MeusDadosReq) {
  return api.update(`CadastroCliente/meus-dados`, params)
}

export function salvarMatricula(params: MatriculaReq) {
  return api.create(`CadastroCliente/matricula`, params)
}

export function removerMatricula(matriculaId: string) {
  return api.delete(`CadastroCliente/matricula?matriculaId=${matriculaId}`)
}
