import { Invalid, Perfil } from 'models/types'
import { MEUS_DADOS, TYPES } from './types'
import { MatriculaReq } from 'models/meusDados/types'

export const setMeusDados = (value: MEUS_DADOS['meusDados']) => ({
  type: TYPES.SET_MEUS_DADOS,
  value
})

type MeusDadosField = keyof MEUS_DADOS['meusDados']
export const setMeusDadosField = <T extends MeusDadosField>(
  value: T extends keyof MEUS_DADOS['meusDados']
    ? MEUS_DADOS['meusDados'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_MEUS_DADOS_FIELD,
  value,
  field
})

export const cleanMeusDados = () => ({
  type: TYPES.CLEAN_MEUS_DADOS
})

export const setInvalidMeusDados = (value: MEUS_DADOS['invalidMeusDados']) => ({
  type: TYPES.SET_INVALID_MEUS_DADOS,
  value
})

type InvalidMeusDadosField = keyof MEUS_DADOS['invalidMeusDados']
export const setInvalidMeusDadosField = <T extends InvalidMeusDadosField>(
  value: T extends keyof MEUS_DADOS['invalidMeusDados']
    ? MEUS_DADOS['invalidMeusDados'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_MEUS_DADOS_FIELD,
  value,
  field
})

export const setInvalidMeusDadosFieldArray = <
  T extends InvalidMeusDadosField,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  N extends keyof MEUS_DADOS['invalidMeusDados'][T][number]
>(
  value: Invalid,
  field: T,
  index: number,
  nestedNameArray: N
) => ({
  type: TYPES.SET_INVALID_MEUS_DADOS_FIELD_ARRAY,
  value,
  field,
  index,
  nestedNameArray
})

export const cleanInvalidMeusDados = () => ({
  type: TYPES.CLEAN_INVALID_MEUS_DADOS
})

export const setActiveTabBeneficio = (value: number) => ({
  type: TYPES.SET_ACTIVE_TAB_BENEFICIO,
  value
})

export const adicionarPerfil = (value: Perfil) => ({
  type: TYPES.ADICIONAR_PERFIL,
  value
})

export const removerPerfil = (indexPerfil: number) => ({
  type: TYPES.REMOVER_PERFIL,
  indexPerfil
})

export const removerMatriculaSaga = (id: string, indexMatricula: number) => ({
  type: TYPES.REMOVER_MATRICULA_SAGA,
  id,
  indexMatricula
})

type OptionsSelectFields = keyof MEUS_DADOS['optionsSelects']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setOptionsSelect = (value: any[], field: OptionsSelectFields) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

type LoadingSelectFields = keyof MEUS_DADOS['loadingSelects']
export const setLoadingSelect = (
  value: boolean,
  field: LoadingSelectFields
) => ({
  type: TYPES.SET_LOADING_SELECTS,
  value,
  field
})

export const getPerfisSaga = () => ({
  type: TYPES.GET_PERFIS_SAGA
})

export const getGenerosSaga = () => ({
  type: TYPES.GET_GENEROS_SAGA
})

export const getOrgaosEmissoresSaga = () => ({
  type: TYPES.GET_ORGAOS_EMISSORES_SAGA
})

export const getUfsOrgaosEmissoresSaga = () => ({
  type: TYPES.GET_UFS_ORGAOS_EMISSOR_SAGA
})

export const getEscolaridadesSaga = () => ({
  type: TYPES.GET_ESCOLARIDADES_SAGA
})

export const getUfsNascimentosSaga = () => ({
  type: TYPES.GET_UFS_NASCIMENTO_SAGA
})

export const getCidadesNascimentoSaga = (siglaEstado: string) => ({
  type: TYPES.GET_CIDADES_NASCIMENTO_SAGA,
  siglaEstado
})

export const getEstadosCivisSaga = () => ({
  type: TYPES.GET_ESTADOS_CIVIS_SAGA
})

export const getUfsSaga = () => ({
  type: TYPES.GET_UFS_SAGA
})

export const getCidadesSaga = (siglaEstado: string) => ({
  type: TYPES.GET_CIDADES_SAGA,
  siglaEstado
})

export const getTiposContaSaga = () => ({
  type: TYPES.GET_TIPOS_CONTA_SAGA
})

export const getBancosSaga = () => ({
  type: TYPES.GET_BANCOS_SAGA
})

export const getConveniosSaga = () => ({
  type: TYPES.GET_CONVENIOS_SAGA
})

export const getProfissoesSaga = () => ({
  type: TYPES.GET_PROFISSOES_SAGA
})

export const getDadosEnderecoSaga = () => ({
  type: TYPES.GET_DADOS_ENDERECO_SAGA
})

export const salvarInformacoesSaga = () => ({
  type: TYPES.SALVAR_INFORMACOES_SAGA
})

export const salvarMatriculaSaga = (matriculas: MatriculaReq[]) => ({
  type: TYPES.SALVAR_MATRICULA_SAGA,
  matriculas
})

export const getInformacoesSaga = () => ({
  type: TYPES.GET_INFORMACOES_SAGA
})

export const cleanFieldsInvalidCep = () => ({
  type: TYPES.CLEAN_FIELDS_INVALID_CEP
})

export const toggleModalIN100 = (value: boolean) => ({
  type: TYPES.TOGGLE_MODAL_IN100,
  value
})
