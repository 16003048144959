import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from 'assets/svg/logo-riber.svg'
import iconUser from 'assets/svg/icon-green-user.svg'
import jwtDecode from 'jwt-decode'
import { TokenJwt } from 'models/types'
import { NAVBAR } from 'store/modules/navbar/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import * as navbarActions from 'store/modules/navbar/actions'
import useRedux from 'hooks/useRedux'
import { APICore } from 'helpers/api/apiCore'
import { FiLogIn } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import * as loginActions from 'store/modules/login/actions'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import ModalTrocarSenha from 'components/modalSenha'

function Navbar() {
  const { nomeUsuario } = useSelector<ApplicationState, NAVBAR>(
    (state) => state.navbar
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showSimulacaoOption, setShowSimulacaoOption] = useState(true)

  const location = useLocation()

  function toggleModal() {
    setIsModalOpen(!isModalOpen)
  }

  const AUTH_SESSION_KEY = 'TOKEN_KEY'

  const api = new APICore()

  const { dispatch } = useRedux()

  useEffect(() => {
    const token = sessionStorage.getItem(AUTH_SESSION_KEY)
    const tokenDecode: TokenJwt | null = token ? jwtDecode(token || '') : null
    if (tokenDecode && tokenDecode.name) {
      const nomeFormated = tokenDecode.name.split(' ')
      dispatch(
        navbarActions.setNomeUsuario(
          `${nomeFormated?.[0] || ''} ${nomeFormated?.[1] || ''}`
        )
      )
    }
  }, [dispatch])

  useEffect(() => {
    setShowSimulacaoOption(location.pathname !== '/simulacao')
  }, [location.pathname])

  function logout() {
    dispatch(loginActions.logout())
  }

  function getSimulacoes() {
    window.location.replace('/simulacao')
  }

  const scrollWithOffset = (el: HTMLElement, offset: number) => {
    const elementPosition = el.offsetTop - offset
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <header>
      <nav className="navbar flex-lg-row flex-column navbar-expand-lg navbar-light justify-content-between">
        <div className="d-flex justify-content-between w-100">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo navbar Riber" width={38} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end w-100"
          id="navbar"
        >
          <ul className="navbar-nav d-flex column-gap-15">
            <li className="nav-item">
              <UncontrolledDropdown className="dropdown-custom">
                <DropdownToggle className="nav-link" caret>
                  Produtos
                </DropdownToggle>
                <DropdownMenu>
                  <a className="nav-link" href="/produtos/credito-consignado">
                    <DropdownItem>Crédito consignado</DropdownItem>
                  </a>
                  <a className="nav-link" href="/produtos/cartao-consignado">
                    <DropdownItem>Cartão consignado</DropdownItem>
                  </a>
                  <a className="nav-link" href="/produtos/FGTS">
                    <DropdownItem>
                      Antecipação saque aniversário FGTS
                    </DropdownItem>
                  </a>
                  <a className="nav-link" href="/produtos/credito-pessoal">
                    <DropdownItem>Crédito pessoal</DropdownItem>
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li>
            <li className="nav-item">
              <HashLink
                to="/"
                smooth
                scroll={(el) => {
                  scrollWithOffset(el, 150)
                }}
                className="nav-link"
              >
                Conteúdo
              </HashLink>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                href="https://campanhas.ribercred.com.br/sobre"
                className="nav-link"
                rel="noreferrer"
              >
                Quem somos
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                href="https://campanhas.ribercred.com.br/carreiras"
                className="nav-link"
                rel="noreferrer"
              >
                Carreiras
              </a>
            </li>
            {api.isAuthenticated() ? (
              <li className="nav-item">
                <div className="dropdown show">
                  <a
                    className="btn d-flex align-items-center gap-2 dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={iconUser} alt="Icone de usuário" width={33} />
                    {nomeUsuario}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {showSimulacaoOption && (
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          getSimulacoes()
                        }}
                      >
                        Voltar para a simulação
                      </a>
                    )}
                    <div>
                      <ul className="navbar-nav d-flex column-gap-15">
                        <li className="nav-item">
                          <span
                            className="dropdown-item"
                            onClick={toggleModal}
                            style={{ cursor: 'pointer' }}
                          >
                            Alterar senha
                          </span>
                        </li>
                      </ul>

                      {isModalOpen && (
                        <ModalTrocarSenha
                          isOpen={isModalOpen}
                          toggle={toggleModal}
                        />
                      )}
                    </div>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        logout()
                      }}
                    >
                      Sair
                    </a>
                  </div>
                </div>
              </li>
            ) : (
              <li className="nav-item">
                <Link to="/login" className="nav-link">
                  <FiLogIn size={20} className="icon" /> Entrar
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Navbar
