import { APICore } from 'helpers/api/apiCore'
import { CadastroInicialReq } from 'models/cadastros/cadastroInicial/types'

const api = new APICore()

export function salvarCadastroInicial(params: CadastroInicialReq) {
  return api.create(`CadastroCliente/inicial`, params)
}

export function getPoliticaPrivacidade() {
  return api.get(`pdf/politica-privacidade`)
}
