import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import { MEUS_DADOS } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import * as meusDadosController from 'controller/meusDadosController'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import {
  maskCEP,
  maskCPF,
  maskMoneyReq,
  removeMaskCEP,
  removeMaskCPF,
  removeMaskMoney
} from 'util/masks'
import {
  Matricula,
  MeusDados,
  MeusDadosReq,
  MatriculaReq
} from 'models/meusDados/types'

interface GenericProps {
  type: string
}

interface GetCidades extends GenericProps {
  siglaEstado: string
}

function* getPerfis(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'perfis'))
    const response = yield call(meusDadosController.getPerfis)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'perfis')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'perfis'))
  }
}

function* getGeneros(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'generos'))
    const response = yield call(meusDadosController.getGeneros)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'generos')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'generos'))
  }
}

function* getOrgaosEmissores(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'orgaosEmissor'))
    const response = yield call(meusDadosController.getOrgaosEmissores)
    yield put(
      meusDadosActions.setOptionsSelect(
        response?.data?.content,
        'orgaosEmissor'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'orgaosEmissor'))
  }
}

function* getUfsOrgaosEmissores(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'ufsOrgaoEmissor'))
    const response = yield call(meusDadosController.getEstados)
    yield put(
      meusDadosActions.setOptionsSelect(
        response?.data?.content,
        'ufsOrgaoEmissor'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'ufsOrgaoEmissor'))
  }
}

function* getEscolaridades(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'escolaridades'))
    const response = yield call(meusDadosController.getEscolaridades)
    yield put(
      meusDadosActions.setOptionsSelect(
        response?.data?.content,
        'escolaridades'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'escolaridades'))
  }
}

function* getUfsNascimentos(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'ufsNascimento'))
    const response = yield call(meusDadosController.getEstados)
    yield put(
      meusDadosActions.setOptionsSelect(
        response?.data?.content,
        'ufsNascimento'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'ufsNascimento'))
  }
}

function* getCidadesNascimento({ siglaEstado }: GetCidades): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'cidadesNascimento'))
    const response = yield call(meusDadosController.getCidades, siglaEstado)
    yield put(
      meusDadosActions.setOptionsSelect(
        response?.data?.content,
        'cidadesNascimento'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'cidadesNascimento'))
  }
}

function* getEstadosCivis(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'estadosCivis'))
    const response = yield call(meusDadosController.getEstadosCivis)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'estadosCivis')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'estadosCivis'))
  }
}

function* getUfs(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'ufs'))
    const response = yield call(meusDadosController.getEstados)
    yield put(meusDadosActions.setOptionsSelect(response?.data?.content, 'ufs'))
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'ufs'))
  }
}

function* getCidades({ siglaEstado }: GetCidades): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'cidades'))
    const response = yield call(meusDadosController.getCidades, siglaEstado)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'cidades')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'cidades'))
  }
}

function* getTiposConta(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'tiposConta'))
    const response = yield call(meusDadosController.getTiposConta)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'tiposConta')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'tiposConta'))
  }
}

function* getBancos(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'bancos'))
    const response = yield call(meusDadosController.getBancos)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'bancos')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'bancos'))
  }
}

function* getConvenios(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'convenios'))
    const response = yield call(meusDadosController.getConvenios)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'convenios')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'convenios'))
  }
}

function* getProfissoes(): SagaIterator {
  try {
    yield put(meusDadosActions.setLoadingSelect(true, 'profissoes'))
    const response = yield call(meusDadosController.getProfissoes)
    yield put(
      meusDadosActions.setOptionsSelect(response?.data?.content, 'profissoes')
    )
  } catch (error) {
    //
  } finally {
    yield put(meusDadosActions.setLoadingSelect(false, 'profissoes'))
  }
}

function* getDadosEndereco(): SagaIterator {
  const { meusDados }: MEUS_DADOS = yield select(
    (state: ApplicationState) => state.meusDados
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cepFormatted = removeMaskCEP(meusDados.cep)
    const response = yield call(
      meusDadosController.getDadosEndereco,
      cepFormatted
    )
    yield put(
      meusDadosActions.setMeusDadosField(
        response?.data?.content.uf,
        'siglaEstado'
      )
    )
    yield put(
      meusDadosActions.setMeusDadosField(
        response?.data?.content.logradouro,
        'logradouro'
      )
    )
    yield put(
      meusDadosActions.setMeusDadosField(
        response?.data?.content.bairro,
        'bairro'
      )
    )
    yield put(
      meusDadosActions.setMeusDadosField(
        response?.data?.content.localidade?.toUpperCase(),
        'cidade'
      )
    )
  } catch (error) {
    yield put(meusDadosActions.cleanFieldsInvalidCep())
    handlerError(error, 'Não foi possível buscar os dados de endereço!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarInformacoes(): SagaIterator {
  const { meusDados }: MEUS_DADOS = yield select(
    (state: ApplicationState) => state.meusDados
  )
  try {
    yield put(loadingActions.setLoading(true))
    const infosFormatted: MeusDadosReq = {
      nome: meusDados.nome,
      cpf: removeMaskCPF(meusDados.cpf),
      dataNascimento: meusDados.dataNascimento,
      nomePai: meusDados.nomePai,
      nomeMae: meusDados.nomeMae,
      rg: meusDados.rg,
      orgaoEmissorId: meusDados.orgaoEmissorId,
      ufOrgaoEmissor: meusDados.ufOrgaoEmissor,
      dataEmissao: meusDados.dataEmissao,
      ddd: meusDados.ddd,
      numero: meusDados.numero,
      email: meusDados.email,
      sexo: meusDados.sexo,
      estadoCivil: meusDados.estadoCivil,
      cidadeNascimento: meusDados.cidadeNascimento,
      ufNascimento: meusDados.ufNascimento,
      matriculas:
        meusDados.matriculas?.map((matricula) => {
          return {
            id: matricula.id,
            numeroMatricula: matricula.numeroMatricula,
            tipoPerfil: matricula.tipoPerfil,
            descricaoPerfil: matricula.descricaoPerfil,
            tipoPerfilId: matricula.tipoPerfilId,
            convenioId: matricula.convenioId,
            margemDisponivel: Number(
              removeMaskMoney(matricula.margemDisponivel)
            ),
            margemCartaoDisponivel: Number(
              removeMaskMoney(matricula.margemCartaoDisponivel)
            ),
            margemCartaoBeneficio: Number(
              removeMaskMoney(matricula.margemCartaoBeneficio)
            ),
            valorRendaIndividual: Number(
              removeMaskMoney(matricula.valorRendaIndividual)
            ),
            contaCorrenteId: matricula.contaCorrenteId,
            tipoContaId: matricula.tipoContaId,
            bancoDadosBancariosId: matricula.bancoDadosBancariosId,
            numeroAgencia: matricula.numeroAgencia ?? '',
            digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
            numeroConta: matricula.numeroConta ?? '',
            digitoVerificadorConta: matricula.digitoVerificadorConta ?? ''
          }
        }) || null,
      escolaridadeId: meusDados.escolaridadeId,
      cep: meusDados.cep,
      cidade: meusDados.cidade,
      siglaEstado: meusDados.siglaEstado,
      logradouro: meusDados.logradouro,
      numeroEndereco: meusDados.numeroEndereco,
      bairro: meusDados.bairro,
      complemento: meusDados.complemento,
      nomeConjuge: meusDados.nomeConjuge,
      profissaoId: meusDados.profissaoId,
      novaSenha: meusDados.novaSenha
    }
    yield call(meusDadosController.salvarInformacoes, infosFormatted)
    toast.success('Informações atualizadas com sucesso!')
    yield put(meusDadosActions.getInformacoesSaga())
    yield put(simulacaoActions.validaStatusIN100Saga())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface SalvarMatriculaProps extends GenericProps {
  matriculas: MatriculaReq[]
}

function* salvarMatricula({ matriculas }: SalvarMatriculaProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))

    const requests = matriculas.map((matricula) =>
      call(function* () {
        try {
          yield call(meusDadosController.salvarMatricula, matricula)
          toast.success(
            `Matricula ${matricula.numeroMatricula} ${
              matricula.id ? 'atualizada' : 'cadastrada'
            } com sucesso`
          )
        } catch (error) {
          toast.error(
            `Erro ao ${matricula.id ? 'atualizar' : 'cadastrar'} matricula ${
              matricula.numeroMatricula
            }`
          )
        }
      })
    )

    yield all(requests)
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface RemoverMatriculaProps extends GenericProps {
  id: string
  indexMatricula: number
}

function* removerMatricula({
  id,
  indexMatricula
}: RemoverMatriculaProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))

    const response = yield call(meusDadosController.removerMatricula, id)
    yield put(meusDadosActions.removerPerfil(indexMatricula))
    toast.success(
      `Matricula ${response?.data?.content.numero} removida com sucesso`
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getInformacoes(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(meusDadosController.getInformacoes)
    const infosFormatted: MeusDados = {
      bairro: response?.data?.content?.bairro,
      cep: maskCEP(response?.data?.content?.cep),
      cidade: response?.data?.content?.cidade,
      cidadeNascimento: response?.data?.content?.cidadeNascimento,
      complemento: response?.data?.content?.complemento,
      cpf: maskCPF(response?.data?.content?.cpf),
      dataEmissao: response?.data?.content?.dataEmissao,
      dataNascimento: response?.data?.content?.dataNascimento,
      ddd: response?.data?.content?.ddd,
      email: response?.data?.content?.email,
      escolaridadeId: response?.data?.content?.escolaridadeId,
      estadoCivil: response?.data?.content?.estadoCivil,
      logradouro: response?.data?.content?.logradouro,
      matriculas:
        response?.data?.content?.matriculas?.map((matricula: Matricula) => {
          return {
            id: matricula.id,
            numeroMatricula: matricula.numeroMatricula,
            tipoPerfil: matricula.tipoPerfil,
            descricaoPerfil: matricula.descricaoPerfil,
            tipoPerfilId: matricula.tipoPerfilId,
            convenioId: matricula.convenioId,
            margemCartaoDisponivel: maskMoneyReq(
              matricula.margemCartaoDisponivel || 0
            ),
            margemDisponivel: maskMoneyReq(matricula.margemDisponivel || 0),
            margemCartaoBeneficio: maskMoneyReq(
              matricula.margemCartaoBeneficio || 0
            ),
            valorRendaIndividual: maskMoneyReq(
              matricula.valorRendaIndividual || 0
            ),
            contaCorrenteId: matricula.contaCorrenteId,
            tipoContaId: matricula.tipoContaId,
            bancoDadosBancariosId: matricula.bancoDadosBancariosId,
            numeroAgencia: matricula.numeroAgencia,
            digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
            numeroConta: matricula.numeroConta,
            digitoVerificadorConta: matricula.digitoVerificadorConta
          }
        }) || [],
      nome: response?.data?.content?.nome,
      nomeConjuge: response?.data?.content?.nomeConjuge,
      nomeMae: response?.data?.content?.nomeMae,
      nomePai: response?.data?.content?.nomePai,
      numero: response?.data?.content?.numero,
      numeroEndereco: response?.data?.content?.numeroEndereco,
      rg: response?.data?.content?.rg,
      orgaoEmissorId: response?.data?.content?.orgaoEmissorId,
      profissaoId: response?.data?.content?.profissaoId,
      sexo: response?.data?.content?.sexo,
      siglaEstado: response?.data?.content?.siglaEstado,
      ufNascimento: response?.data?.content?.ufNascimento,
      ufOrgaoEmissor: response?.data?.content?.ufOrgaoEmissor,
      novaSenha: response?.data?.content?.novaSenha
    }
    const matriculasInvalids: MEUS_DADOS['invalidMeusDados'] = {
      nome: {
        invalid: false,
        message: ''
      },
      cpf: {
        invalid: false,
        message: ''
      },
      dataNascimento: {
        invalid: false,
        message: ''
      },
      nomePai: {
        invalid: false,
        message: ''
      },
      nomeMae: {
        invalid: false,
        message: ''
      },
      rg: {
        invalid: false,
        message: ''
      },
      orgaoEmissorId: {
        invalid: false,
        message: ''
      },
      ufOrgaoEmissor: {
        invalid: false,
        message: ''
      },
      dataEmissao: {
        invalid: false,
        message: ''
      },
      ddd: {
        invalid: false,
        message: ''
      },
      numero: {
        invalid: false,
        message: ''
      },
      email: {
        invalid: false,
        message: ''
      },
      sexo: {
        invalid: false,
        message: ''
      },
      estadoCivil: {
        invalid: false,
        message: ''
      },
      nomeConjuge: {
        invalid: false,
        message: ''
      },
      cidadeNascimento: {
        invalid: false,
        message: ''
      },
      ufNascimento: {
        invalid: false,
        message: ''
      },
      matriculas:
        infosFormatted.matriculas?.map(() => {
          return {
            id: {
              invalid: false,
              message: ''
            },
            numeroMatricula: {
              invalid: false,
              message: ''
            },
            tipoPerfil: {
              invalid: false,
              message: ''
            },
            descricaoPerfil: {
              invalid: false,
              message: ''
            },
            tipoPerfilId: {
              invalid: false,
              message: ''
            },
            convenioId: {
              invalid: false,
              message: ''
            },
            margemDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoBeneficio: {
              invalid: false,
              message: ''
            },
            valorRendaIndividual: {
              invalid: false,
              message: ''
            },
            contaCorrenteId: {
              invalid: false,
              message: ''
            },
            tipoContaId: {
              invalid: false,
              message: ''
            },
            bancoDadosBancariosId: {
              invalid: false,
              message: ''
            },
            numeroAgencia: {
              invalid: false,
              message: ''
            },
            digitoVerificadorAgencia: {
              invalid: false,
              message: ''
            },
            numeroConta: {
              invalid: false,
              message: ''
            },
            digitoVerificadorConta: {
              invalid: false,
              message: ''
            }
          }
        }) ?? [],
      escolaridadeId: {
        invalid: false,
        message: ''
      },
      cep: {
        invalid: false,
        message: ''
      },
      cidade: {
        invalid: false,
        message: ''
      },
      siglaEstado: {
        invalid: false,
        message: ''
      },
      logradouro: {
        invalid: false,
        message: ''
      },
      numeroEndereco: {
        invalid: false,
        message: ''
      },
      bairro: {
        invalid: false,
        message: ''
      },
      complemento: {
        invalid: false,
        message: ''
      },
      profissaoId: {
        invalid: false,
        message: ''
      },
      novaSenha: {
        invalid: false,
        message: ''
      }
    }
    yield put(meusDadosActions.setInvalidMeusDados(matriculasInvalids))
    yield put(meusDadosActions.setMeusDados(infosFormatted))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetPerfis() {
  yield takeEvery(TYPES.GET_PERFIS_SAGA, getPerfis)
}

export function* watchGetGeneros() {
  yield takeEvery(TYPES.GET_GENEROS_SAGA, getGeneros)
}

export function* watchGetOrgaosEmissores() {
  yield takeEvery(TYPES.GET_ORGAOS_EMISSORES_SAGA, getOrgaosEmissores)
}

export function* watchGetUfsOrgaosEmissores() {
  yield takeEvery(TYPES.GET_UFS_ORGAOS_EMISSOR_SAGA, getUfsOrgaosEmissores)
}

export function* watchGetEscolaridades() {
  yield takeEvery(TYPES.GET_ESCOLARIDADES_SAGA, getEscolaridades)
}

export function* watchGetUfsNascimentos() {
  yield takeEvery(TYPES.GET_UFS_NASCIMENTO_SAGA, getUfsNascimentos)
}

export function* watchGetCidadesNascimento() {
  yield takeEvery(TYPES.GET_CIDADES_NASCIMENTO_SAGA, getCidadesNascimento)
}

export function* watchGetEstadosCivis() {
  yield takeEvery(TYPES.GET_ESTADOS_CIVIS_SAGA, getEstadosCivis)
}

export function* watchGetUfs() {
  yield takeEvery(TYPES.GET_UFS_SAGA, getUfs)
}

export function* watchGetCidades() {
  yield takeEvery(TYPES.GET_CIDADES_SAGA, getCidades)
}

export function* watchGetTiposConta() {
  yield takeEvery(TYPES.GET_TIPOS_CONTA_SAGA, getTiposConta)
}

export function* watchGetBancos() {
  yield takeEvery(TYPES.GET_BANCOS_SAGA, getBancos)
}

export function* watchGetConvenios() {
  yield takeEvery(TYPES.GET_CONVENIOS_SAGA, getConvenios)
}

export function* watchGetProfissoes() {
  yield takeEvery(TYPES.GET_PROFISSOES_SAGA, getProfissoes)
}

export function* watchGetDadosEnderecos() {
  yield takeEvery(TYPES.GET_DADOS_ENDERECO_SAGA, getDadosEndereco)
}

export function* watchSalvarInformacoes() {
  yield takeEvery(TYPES.SALVAR_INFORMACOES_SAGA, salvarInformacoes)
}

export function* watchSalvarMatricula() {
  yield takeEvery(TYPES.SALVAR_MATRICULA_SAGA, salvarMatricula)
}

export function* watchRemoverMatricula() {
  yield takeEvery(TYPES.REMOVER_MATRICULA_SAGA, removerMatricula)
}

export function* watchGetInformacoes() {
  yield takeEvery(TYPES.GET_INFORMACOES_SAGA, getInformacoes)
}

function* meusDados() {
  yield all([
    fork(watchGetPerfis),
    fork(watchGetGeneros),
    fork(watchGetOrgaosEmissores),
    fork(watchGetUfsOrgaosEmissores),
    fork(watchGetEscolaridades),
    fork(watchGetUfsNascimentos),
    fork(watchGetCidadesNascimento),
    fork(watchGetEstadosCivis),
    fork(watchGetUfs),
    fork(watchGetCidades),
    fork(watchGetTiposConta),
    fork(watchGetBancos),
    fork(watchGetConvenios),
    fork(watchGetProfissoes),
    fork(watchGetDadosEnderecos),
    fork(watchSalvarInformacoes),
    fork(watchSalvarMatricula),
    fork(watchRemoverMatricula),
    fork(watchGetInformacoes)
  ])
}

export default meusDados
