import { MeusDados } from 'models/meusDados/types'
import {
  Banco,
  Cidade,
  Convenio,
  Escolaridade,
  Estado,
  EstadoCivil,
  Genero,
  Invalid,
  InvalidArray,
  OrgaoEmissor,
  Perfil,
  Profissao,
  TipoConta
} from 'models/types'

export enum TYPES {
  SET_MEUS_DADOS = '@@meusDados/SET_MEUS_DADOS',
  SET_MEUS_DADOS_FIELD = '@@meusDados/SET_MEUS_DADOS_FIELD',
  CLEAN_MEUS_DADOS = '@@meusDados/CLEAN_MEUS_DADOS',
  SET_INVALID_MEUS_DADOS = '@@meusDados/SET_INVALID_MEUS_DADOS',
  SET_INVALID_MEUS_DADOS_FIELD = '@@meusDados/SET_INVALID_MEUS_DADOS_FIELD',
  SET_INVALID_MEUS_DADOS_FIELD_ARRAY = '@@meusDados/SET_INVALID_MEUS_DADOS_FIELD_ARRAY',
  CLEAN_INVALID_MEUS_DADOS = '@@meusDados/CLEAN_INVALID_MEUS_DADOS',
  SET_ACTIVE_TAB_BENEFICIO = '@@meusDados/SET_ACTIVE_TAB_BENEFICIO',
  ADICIONAR_PERFIL = '@@meusDados/ADICIONAR_PERFIL',
  REMOVER_PERFIL = '@@meusDados/REMOVER_PERFIL',
  REMOVER_MATRICULA_SAGA = '@@meusDados/REMOVER_PERFIL_SAGA',
  SET_OPTIONS_SELECT = '@@meusDados/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@meusDados/SET_LOADING_SELECTS',
  CLEAN_FIELDS_INVALID_CEP = '@@meusDados/CLEAN_FIELDS_INVALID_CEP',

  GET_PERFIS_SAGA = '@@meusDados/GET_PERFIS_SAGA',
  GET_GENEROS_SAGA = '@@meusDados/GET_GENEROS_SAGA',
  GET_ORGAOS_EMISSORES_SAGA = '@@meusDados/GET_ORGAOS_EMISSORES_SAGA',
  GET_UFS_ORGAOS_EMISSOR_SAGA = '@@meusDados/GET_UFS_ORGAOS_EMISSOR_SAGA',
  GET_ESCOLARIDADES_SAGA = '@@meusDados/GET_ESCOLARIDADES_SAGA',
  GET_UFS_NASCIMENTO_SAGA = '@@meusDados/GET_UFS_NASCIMENTO_SAGA',
  GET_CIDADES_NASCIMENTO_SAGA = '@@meusDados/GET_CIDADES_NASCIMENTO_SAGA',
  GET_ESTADOS_CIVIS_SAGA = '@@meusDados/GET_ESTADOS_CIVIS_SAGA',
  GET_UFS_SAGA = '@@meusDados/GET_UFS_SAGA',
  GET_CIDADES_SAGA = '@@meusDados/GET_CIDADES_SAGA',
  GET_TIPOS_CONTA_SAGA = '@@meusDados/GET_TIPOS_CONTA_SAGA',
  GET_BANCOS_SAGA = '@@meusDados/GET_BANCOS_SAGA',
  GET_CONVENIOS_SAGA = '@@meusDados/GET_CONVENIOS_SAGA',
  GET_PROFISSOES_SAGA = '@@meusDados/GET_PROFISSOES_SAGA',
  GET_DADOS_ENDERECO_SAGA = '@@meusDados/GET_DADOS_ENDERECO_SAGA',
  SALVAR_INFORMACOES_SAGA = '@@meusDados/SALVAR_INFORMACOES_SAGA',
  SALVAR_MATRICULA_SAGA = '@@meusDados/SALVAR_MATRICLA_SAGA',
  GET_INFORMACOES_SAGA = '@@meusDados/GET_INFORMACOES_SAGA',
  TOGGLE_MODAL_IN100 = '@@meusDados/TOGGLE_MODAL_IN100',
  VALIDA_STATUS_IN100_SAGA = '@@simulacao/VALIDA_STATUS_IN100_SAGA'
}

export interface MEUS_DADOS {
  meusDados: MeusDados
  optionsSelects: OptionsSelects
  loadingSelects: LoadingSelects
  invalidMeusDados: InvalidMeusDados
  activeTabBeneficio: number
  modalIN100: boolean
}

type InvalidMeusDados = {
  [K in keyof MeusDados]: K extends 'matriculas'
    ? InvalidArray<MeusDados['matriculas'][number]>[]
    : Invalid
}

interface OptionsSelects {
  perfis: Perfil[]
  generos: Genero[]
  orgaosEmissor: OrgaoEmissor[]
  ufsOrgaoEmissor: Estado[]
  escolaridades: Escolaridade[]
  ufsNascimento: Estado[]
  cidadesNascimento: Cidade[]
  estadosCivis: EstadoCivil[]
  ufs: Estado[]
  cidades: Cidade[]
  tiposConta: TipoConta[]
  bancos: Banco[]
  convenios: Convenio[]
  profissoes: Profissao[]
}

type LoadingSelects = {
  [k in keyof OptionsSelects]: boolean
}
