import React, { useEffect, useState } from 'react'
import { FiChevronRight, FiDollarSign } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { maskMoney, removeMaskMoney } from 'util/masks'
import {
  CardProdutoContainer,
  // Checkbox,
  CheckboxProduto,
  ContentProduto,
  InvisibleInput
} from '../style'
import { ProdutosSimulacao } from 'models/types'
import { EnumServicos } from 'models/enums'
import { Range } from 'styles/global'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import useRedux from 'hooks/useRedux'
import { SIMULACAO } from 'store/modules/simulacao/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'

interface CardProdutoProps {
  produto: ProdutosSimulacao
  checked: boolean
  onCheck: (e: ProdutosSimulacao) => void
}
interface InfoProduto {
  listProdutos?: {
    listParcelas?: {
      parcelas: number
      valorTotalFinanciado: number
    }[]
  }[]
}

interface ParcelaMaisAltaInfo {
  parcelas: number
  valorTotalFinanciado: number
}

function CardProduto({ produto, checked, onCheck }: CardProdutoProps) {
  const { detalhamentoIsOpen, produtoSelected } = useSelector<
    ApplicationState,
    SIMULACAO
  >((state) => state.simulacao)

  const { dispatch } = useRedux()
  const [invalid, setInvalid] = useState(false)

  useEffect(() => {
    if (
      ((produto.servico === 5 ||
        produto.servico === 6 ||
        produto.servico === 8 ||
        produto.servico === 12) &&
        Number(removeMaskMoney(produto.valorSaque)) <
          produto.valorSaqueMinimo) ||
      Number(removeMaskMoney(produto.valorSaque)) > produto.valorSaqueMaximo
    ) {
      setInvalid(true)
    } else {
      setInvalid(false)
    }
  }, [
    produto.servico,
    produto.valorSaque,
    produto.valorSaqueMaximo,
    produto.valorSaqueMinimo
  ])

  function encontrarParcelaMaisAlta(
    produto: InfoProduto
  ): ParcelaMaisAltaInfo | null {
    let parcelaMaisAlta: ParcelaMaisAltaInfo | null = null
    let maxQuantidadeParcelas = 0

    if (produto.listProdutos && produto.listProdutos.length > 0) {
      produto.listProdutos.forEach((item) => {
        if (item.listParcelas && item.listParcelas.length > 0) {
          item.listParcelas.forEach((parcela) => {
            if (parcela.parcelas > maxQuantidadeParcelas) {
              maxQuantidadeParcelas = parcela.parcelas
              parcelaMaisAlta = {
                parcelas: parcela.parcelas,
                valorTotalFinanciado: parcela.valorTotalFinanciado
              }
            }
          })
        }
      })
    }

    return parcelaMaisAlta
  }

  switch (produto.servico) {
    case 5:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto ">
                R$
                <InvisibleInput
                  onChange={(e) => {
                    dispatch(
                      simulacaoActions.setInfosSaqueComplementarConsignado(
                        maskMoney(e.target.value),
                        'valorSaque',
                        produto.matricula
                      )
                    )
                  }}
                  value={maskMoney(produto.valorSaque)}
                />
              </span>
              <Range
                type="range"
                step={0.01}
                min={produto.valorSaqueMinimo}
                max={produto.valorSaqueMaximo}
                onChange={(e) => {
                  dispatch(
                    simulacaoActions.setInfosSaqueComplementarConsignado(
                      maskMoney(Number(e.target.value)?.toFixed(2)),
                      'valorSaque',
                      produto.matricula
                    )
                  )
                }}
                value={Number(removeMaskMoney(produto.valorSaque))?.toFixed(2)}
              />
              {invalid && (
                <p className="mensagem-erro">
                  O valor deve estar entre R${' '}
                  {maskMoney(produto.valorSaqueMinimo?.toFixed(2))} e R${' '}
                  {maskMoney(produto.valorSaqueMaximo?.toFixed(2))}
                </p>
              )}
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 6:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center column-gap-10">
                <FiDollarSign className="icon" />
                {produto.matricula && (
                  <label className="matricula">M: {produto.matricula}</label>
                )}
              </div>
              <CheckboxProduto
                checked={checked}
                onChange={() => {
                  onCheck(produto)
                }}
              />
            </div>
            <div className="d-flex flex-column row-gap-15 justify-content-between">
              <label className="nome-produto">
                {EnumServicos[produto.servico]}
              </label>
              <span className="valor-produto">
                R$ {maskMoney(produto.valorLimite?.toFixed(2))}
              </span>
            </div>
            {/*
            Remoção de flags a pedido do cliente
            <div className="d-flex column-gap-10">
              <Checkbox
                onChange={(e) => {
                  dispatch(
                    simulacaoActions.setInfosCartaoConsig(
                      e.target.checked,
                      'flagAberturaConta',
                      produto.matricula
                    )
                  )
                }}
                checked={produto.flagAberturaConta}
              />
              <label className="label-12">Abrir conta</label>
            </div>
            <div className="d-flex column-gap-10">
              <Checkbox
                onChange={(e) => {
                  dispatch(
                    simulacaoActions.setInfosCartaoConsig(
                      e.target.checked,
                      'flagSaqueAutorizado',
                      produto.matricula
                    )
                  )
                }}
                checked={produto.flagSaqueAutorizado}
              />
              <label className="label-12">Saque autorizado</label>
            </div> */}
            <div className="d-flex flex-column h-100 justify-content-end">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto d-flex gap-2">
                R$
                <InvisibleInput
                  onChange={(e) => {
                    dispatch(
                      simulacaoActions.setInfosCartaoConsig(
                        maskMoney(e.target.value),
                        'valorSaque',
                        produto.matricula
                      )
                    )
                  }}
                  value={maskMoney(produto.valorSaque)}
                />
              </span>
              <Range
                type="range"
                step={0.01}
                min={produto.valorSaqueMinimo}
                max={produto.valorSaqueMaximo}
                onChange={(e) => {
                  dispatch(
                    simulacaoActions.setInfosCartaoConsig(
                      maskMoney(Number(e.target.value)?.toFixed(2)),
                      'valorSaque',
                      produto.matricula
                    )
                  )
                }}
                value={Number(removeMaskMoney(produto.valorSaque))?.toFixed(2)}
              />
              {invalid && (
                <p className="mensagem-erro">
                  O valor deve estar entre R${' '}
                  {maskMoney(produto.valorSaqueMinimo?.toFixed(2))} e R${' '}
                  {maskMoney(produto.valorSaqueMaximo?.toFixed(2))}
                </p>
              )}
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 7:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center column-gap-10">
                <FiDollarSign className="icon" />
                {produto.matricula && (
                  <label className="matricula">M: {produto.matricula}</label>
                )}
              </div>
              <CheckboxProduto
                checked={checked}
                onChange={() => {
                  onCheck(produto)
                }}
              />
            </div>
            <div className="d-flex flex-column row-gap-15">
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      produtoSelected.servico === 0 ||
                      produtoSelected.servico === produto.servico
                    ) {
                      dispatch(simulacaoActions.toggleDetalhamento())
                    }
                    if (!detalhamentoIsOpen) {
                      dispatch(
                        simulacaoActions.selectProduto({
                          servico: produto.servico,
                          matricula: produto.matricula
                        })
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
              <div className="d-flex flex-column">
                <label className="label-12">Limite do Cartão:</label>
                <span className="valor-produto">
                  R$ {maskMoney(produto.valorLimite?.toFixed(2))}
                </span>
              </div>
            </div>
            <Row className="d-flex align-items-center">
              <Col xl={6} className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">Quantidade de parcelas:</label>
                  <span className="valor-produto-secondary">
                    {produto.qtdeParcelas}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Valor parcelas:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.valorParcela?.toFixed(2))}
                  </span>
                </div>
              </Col>
              {/* <Col className="d-flex flex-column row-gap-20">
                Removendo flag a pedido do cliente
                <div className="d-flex column-gap-10">
                  <Checkbox
                    onChange={(e) => {
                      dispatch(
                        simulacaoActions.setInfosCartaoBeneficio(
                          e.target.checked,
                          'flagAberturaConta',
                          produto.matricula
                        )
                      )
                    }}
                    checked={produto.flagAberturaConta}
                  />
                  <label className="label-12">Abrir conta</label>
                </div>
                <div className="d-flex column-gap-10">
                  <Checkbox
                    onChange={(e) => {
                      dispatch(
                        simulacaoActions.setInfosCartaoBeneficio(
                          e.target.checked,
                          'flagSaqueAutorizado',
                          produto.matricula
                        )
                      )
                    }}
                    checked={produto.flagSaqueAutorizado}
                    disabled
                  />
                  <label className="label-12">Saque autorizado</label>
                </div>
                <div className="d-flex column-gap-10">
                  <Checkbox
                    onChange={(e) => {
                      dispatch(
                        simulacaoActions.setInfosCartaoBeneficio(
                          e.target.checked,
                          'flagSaqueParcelado',
                          produto.matricula
                        )
                      )
                    }}
                    checked={produto.flagSaqueParcelado}
                    disabled
                  />
                  <label className="label-12">Saque parcelado</label>
                </div>
              </Col> */}
            </Row>
            <div className="d-flex flex-column">
              <label className="label-12">Limite disponível para saque:</label>
              <span className="valor-produto">
                R$ {maskMoney(produto.valorSaque)}
              </span>
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 8:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
              </div>
            </div>
            <div className="d-flex flex-column">
              <label className="label-12">Valor saque:</label>
              <span className="valor-produto d-flex gap-2">
                R$
                <InvisibleInput
                  onChange={(e) => {
                    dispatch(
                      simulacaoActions.setInfosSaqueComplementarBeneficio(
                        maskMoney(e.target.value),
                        'valorSaque',
                        produto.matricula
                      )
                    )
                  }}
                  value={maskMoney(produto.valorSaque)}
                />
              </span>
              <Range
                type="range"
                step={0.01}
                min={produto.valorSaqueMinimo}
                max={produto.valorSaqueMaximo}
                onChange={(e) => {
                  dispatch(
                    simulacaoActions.setInfosSaqueComplementarBeneficio(
                      maskMoney(Number(e.target.value)?.toFixed(2)),
                      'valorSaque',
                      produto.matricula
                    )
                  )
                }}
                value={Number(removeMaskMoney(produto.valorSaque))?.toFixed(2)}
              />
              {invalid && (
                <p className="mensagem-erro">
                  O valor deve estar entre R${' '}
                  {maskMoney(produto.valorSaqueMinimo?.toFixed(2))} e R${' '}
                  {maskMoney(produto.valorSaqueMaximo?.toFixed(2))}
                </p>
              )}
            </div>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 9:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      produtoSelected.servico === 0 ||
                      produtoSelected.servico === produto.servico
                    ) {
                      dispatch(simulacaoActions.toggleDetalhamento())
                    }
                    if (!detalhamentoIsOpen) {
                      dispatch(
                        simulacaoActions.selectProduto({
                          servico: produto.servico,
                          matricula: produto.matricula
                        })
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
            </div>
            <span className="valor-produto">
              R$ {maskMoney(produto.valorTotalFinanciado?.toFixed(2))}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
    case 12:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <label className="label-12">Benefícios:</label>
                  {produto.produtosSINDNAP?.planos.map((sindnap, i) => {
                    return (
                      <div key={i} className="checkbox-wrapper">
                        {sindnap.coberturasField.map(
                          (cobertura, indexCobertura) => {
                            return (
                              <div
                                className="d-flex column-gap-10"
                                key={indexCobertura}
                              >
                                <span className="label-12">
                                  {cobertura.nomeCoberturaField}
                                  {cobertura.valorBeneficioField > 0
                                    ? ' - R$ ' +
                                      maskMoney(
                                        cobertura.valorBeneficioField.toFixed(2)
                                      )
                                    : ''}
                                </span>
                                <br />
                              </div>
                            )
                          }
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <label className="label-12">Valor mensalidade:</label>
            <span className="valor-produto">
              R${' '}
              {maskMoney(
                produto.produtosSINDNAP?.planos[0].valorPremioAux?.toFixed(2)
              )}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
    default:
      return (
        <CardProdutoContainer isDetalhamentoOpen={detalhamentoIsOpen}>
          <ContentProduto className="d-flex justify-content-between">
            <div className="d-flex flex-column row-gap-20">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center column-gap-10">
                  <FiDollarSign className="icon" />
                  {produto.matricula && (
                    <label className="matricula">M: {produto.matricula}</label>
                  )}
                </div>
                <CheckboxProduto
                  checked={checked}
                  onChange={() => {
                    onCheck(produto)
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-between">
                <label className="nome-produto">
                  {EnumServicos[produto.servico]}
                </label>
                <FiChevronRight
                  onClick={() => {
                    if (
                      produtoSelected.servico === 0 ||
                      (produtoSelected.servico === produto.servico &&
                        produtoSelected.matricula === produto.matricula)
                    ) {
                      dispatch(simulacaoActions.toggleDetalhamento())
                    }
                    if (!detalhamentoIsOpen) {
                      dispatch(
                        simulacaoActions.selectProduto({
                          servico: produto.servico,
                          matricula: produto.matricula
                        })
                      )
                    }
                  }}
                  role="button"
                  className="icon"
                />
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label className="label-12">Quantidade de parcelas:</label>
                  <span className="valor-produto-secondary">
                    {produto.qtdeParcelas}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <label className="label-12">Valor parcelas:</label>
                  <span className="valor-produto-secondary">
                    R$ {maskMoney(produto.valorParcela?.toFixed(2))}
                  </span>
                </div>
              </div>
            </div>
            <span className="valor-produto">
              R$ {maskMoney(produto.valorTotalFinanciado?.toFixed(2))}
            </span>
          </ContentProduto>
        </CardProdutoContainer>
      )
  }
}

export default CardProduto
