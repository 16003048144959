import React, { useEffect } from 'react'
import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import Steps from 'components/Steps'
import { Col, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { STEPS_CADASTRO_APOSENTADO } from 'views/cadastros/constants/constants'
import aposentado from 'assets/images/cadastros/aposentado.png'
import InputCustom from 'components/Inputs'
import { CardInfoPurple } from 'views/cadastros/style'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { VALIDACAO } from 'store/modules/cadastros/validacao/types'
import * as validacaoActions from 'store/modules/cadastros/validacao/actions'
import useRedux from 'hooks/useRedux'
import * as yup from 'yup'
import { schemaCelular, schemaToken } from './constants/schema'
import { maskPhoneWithDDD, removeMaskPhone } from 'util/masks'

const Validacao = () => {
  const { validacaoFields, validacaoFieldsInvalids } = useSelector<
    ApplicationState,
    VALIDACAO
  >((state) => state.validacao)

  const { dispatch } = useRedux()

  type ValidacaoFields = keyof VALIDACAO['validacaoFields']
  function onChange<T extends ValidacaoFields>(
    value: T extends keyof VALIDACAO['validacaoFields']
      ? VALIDACAO['validacaoFields'][T]
      : never,
    field: T
  ) {
    dispatch(validacaoActions.setValidacaoFields(value, field))
  }

  useEffect(() => {
    dispatch(validacaoActions.getTelefoneMatriculaInsereSolicitacao())
  }, [dispatch])

  function reenviarSolicitacao() {
    dispatch(
      validacaoActions.setValidacaoFieldsInvalids(
        {
          invalid: false,
          message: ''
        },
        'celular'
      )
    )
    schemaCelular
      .validate(
        {
          celular: removeMaskPhone(validacaoFields.celular)
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(validacaoActions.inserirSolicitacoesIn100Saga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            validacaoActions.setValidacaoFieldsInvalids(
              {
                invalid: true,
                message: e.message
              },
              'celular'
            )
          )
        })
      })
  }

  function validarToken() {
    dispatch(
      validacaoActions.setValidacaoFieldsInvalids(
        {
          invalid: false,
          message: ''
        },
        'codigo'
      )
    )
    schemaToken
      .validate(
        {
          codigo: validacaoFields.codigo
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(validacaoActions.validarTokenIn100Saga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            validacaoActions.setValidacaoFieldsInvalids(
              {
                invalid: true,
                message: e.message
              },
              'codigo'
            )
          )
        })
      })
  }

  return (
    <SimpleCadastro
      banner={aposentado}
      title="Validação dados INSS"
      subTitle="O Banco BMG vai enviar um código via sms para o seu aparelho celular, informe o código no campo ao lado."
    >
      <div className="container-fluid d-flex flex-column row-gap-20">
        <Steps steps={STEPS_CADASTRO_APOSENTADO} currentStep={4} />
        <Row className="justify-content-center">
          <Col md={8}>
            <CardInfoPurple>
              Caso não receba o código no seu aparelho celular, confira seu
              número de telefone e clique abaixo para reenviar o código.
            </CardInfoPurple>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <label className="label-12">Celular*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskPhoneWithDDD(e.target.value), 'celular')
              }}
              value={maskPhoneWithDDD(validacaoFields.celular)}
              invalid={validacaoFieldsInvalids.celular.invalid}
              errorMessage={validacaoFieldsInvalids.celular.message}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={5}>
            <label className="label-12">Insira o código aqui*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value.toUpperCase(), 'codigo')
              }}
              value={validacaoFields.codigo}
              invalid={validacaoFieldsInvalids.codigo.invalid}
              errorMessage={validacaoFieldsInvalids.codigo.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <CustomButton
              background="transparent"
              color="var(--roxo-riber)"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                reenviarSolicitacao()
              }}
            >
              Reenviar código
            </CustomButton>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <CustomButton
              background="var(--roxo-riber)"
              width="100%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                validarToken()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleCadastro>
  )
}

export default Validacao
