import {
  InfosClienteResumo,
  MatriculaResumo,
  MetodosFgts,
  TYPES
} from './types'
import { SagaIterator } from 'redux-saga'
import {
  AllEffect,
  CallEffect,
  all,
  call,
  fork,
  put,
  select,
  takeEvery
} from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import { SIMULACAO } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { handlerErrorSimulacao } from 'util/handlerErroSimulacao'
import { handlerErrorSimulacaoFgts } from 'util/handlerErrorSimulacaoFGTS'
import { maskCPF, maskMoney, removeMaskCPF, removeMaskMoney } from 'util/masks'
import {
  Indicacao,
  ProdutosSimulacao,
  ReqFgts,
  ReqSimuladorSimulacao,
  ResponseWithMessages
} from 'models/types'
import { AxiosResponse } from 'axios'
import { handlerErrorFgts } from 'util/handlerErrorFgts'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import * as simulacaoController from 'controller/simulacaoController'
import moment from 'moment'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import history from 'util/history'
import { MEUS_DADOS } from '../meusDados/types'
import MeusDados from 'views/meusDados'

interface GenericProps {
  type: string
}

function* getClienteMatriculaCpf(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(simulacaoController.getClienteMatriculaCpf)
    yield put(simulacaoActions.setNomeCompleto(response?.data?.content.nome))
    yield put(simulacaoActions.setCpf(response?.data?.content.cpf))
    yield put(
      simulacaoActions.setBeneficios(response?.data?.content.matriculas)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* validaStatusIN100(): SagaIterator {
  const { beneficios, cpf }: SIMULACAO = yield select(
    (state: ApplicationState) => state.simulacao
  )
  const { meusDados }: MEUS_DADOS = yield select(
    (state: ApplicationState) => state.meusDados
  )

  const urlAtual = window.location.href
  try {
    const cpfFormatted = removeMaskCPF(cpf)
    let filteredBeneficios: any = null

    if (urlAtual.endsWith('/meus-dados')) {
      filteredBeneficios = meusDados.matriculas?.filter(
        (matricula) => matricula.tipoPerfil === 1
      )
    } else {
      filteredBeneficios = beneficios?.filter(
        (beneficio) => beneficio.clientePerfil.tipoPerfil === 1
      )
    }

    const matriculasQuePrecisamDeValidacao: string[] = []

    const getValidacoesMatriculasInss = function* (): Generator<
      AllEffect<Generator<CallEffect<AxiosResponse<any, any>>, any, unknown>>,
      void,
      AxiosResponse<ReqSimuladorSimulacao<any>, any>[]
    > {
      yield all(
        filteredBeneficios.map(function* (beneficio: any) {
          try {
            const response: AxiosResponse<
              ReqSimuladorSimulacao<any>,
              any
            > = yield call(
              simulacaoController.validaStatusIN100,
              cpfFormatted,
              urlAtual.endsWith('/meus-dados')
                ? beneficio.numeroMatricula
                : beneficio.numero
            )
            if (response?.data?.modalIn100 === true) {
              matriculasQuePrecisamDeValidacao.push(beneficio.numero)
            }
            return response
          } catch (error) {
            matriculasQuePrecisamDeValidacao.push(
              urlAtual.endsWith('/meus-dados')
                ? beneficio.numeroMatricula
                : beneficio.numero
            )
            handlerErrorSimulacao(
              error,
              'Houve um erro ao tentar realizar uma validação'
            )
            return null
          }
        })
      )
    }

    if (filteredBeneficios.length) {
      yield call(getValidacoesMatriculasInss)
    }

    yield put(
      simulacaoActions.setMatriculasValidacao(matriculasQuePrecisamDeValidacao)
    )

    if (!matriculasQuePrecisamDeValidacao.length) {
      yield put(simulacaoActions.getPropostasSaga())
    } else {
      if (urlAtual.endsWith('/meus-dados')) {
        yield put(meusDadosActions.toggleModalIN100(true))
      }
      yield put(simulacaoActions.toggleModalIN100(true))
    }
  } catch (error) {
    handlerError(error, 'Houve um erro ao tentar realizar uma validação')
  }
}

function* getPropostas(): SagaIterator {
  const { beneficios, cpf }: SIMULACAO = yield select(
    (state: ApplicationState) => state.simulacao
  )
  try {
    yield put(simulacaoActions.setLoadingSimulacao(true))
    yield put(simulacaoActions.setProdutosDisponiveis([]))
    yield put(simulacaoActions.setProdutosSelecionados([]))
    const cpfFormatted = removeMaskCPF(cpf)
    let response: ProdutosSimulacao[] = []
    const getSimulacoes = function* (): Generator<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      AllEffect<Generator<CallEffect<AxiosResponse<any, any>>, any, unknown>>,
      void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      AxiosResponse<ReqSimuladorSimulacao<any>, any>[]
    > {
      const filteredBeneficios = beneficios?.filter(
        (beneficio) => beneficio.clientePerfil.tipoPerfil !== 2
      )
      const responses = yield all(
        filteredBeneficios.map(function* (beneficio) {
          try {
            const response: AxiosResponse<
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ReqSimuladorSimulacao<any>,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              any
            > = yield call(
              simulacaoController.getPropostasCrm,
              cpfFormatted,
              beneficio.numero
            )
            return response
          } catch (error) {
            handlerErrorSimulacao(
              error,
              'Houve um erro ao realizar a simulação, tente novamente mais tarde.'
            )
            return null
          }
        })
      )
      responses?.map((res) => {
        if (Array.isArray(res?.data?.content)) {
          response = response.concat(res?.data?.content)
        }
        if (res?.data?.messages && res?.data?.messages?.length > 0) {
          handlerErrorSimulacao(res?.data)
        }
      })
    }
    yield call(getSimulacoes)
    let produtosDisponiveis: ProdutosSimulacao[] = []
    response.map((produto: ProdutosSimulacao) => {
      produtosDisponiveis.push({
        banco: produto.banco,
        codigoFator: produto.codigoFator,
        codigoFormaEnvioTermo: produto.codigoFormaEnvioTermo,
        codigoProduto: produto.codigoProduto,
        codigoSimulacao: produto.codigoSimulacao,
        cpf: produto.cpf,
        id: produto.id,
        listProdutos: produto.listProdutos,
        listParcelas: produto.listParcelas,
        mensagemOperadora: produto.mensagemOperadora,
        matricula: produto.matricula,
        perfil: produto.perfil,
        porcentagemFator: produto.porcentagemFator,
        qtdeParcelas: produto.qtdeParcelas,
        servico: produto.servico,
        valorDesejado: produto.valorDesejado,
        valorLimite: produto.valorLimite,
        valorParcela: produto.valorParcela,
        valorSaqueMaximo: produto.valorSaqueMaximo,
        valorSaqueMinimo: produto.valorSaqueMinimo,
        valorTotalFinanciado: produto.valorTotalFinanciado,
        flagSaqueAutorizado:
          produto.servico === 5 ||
          produto.servico === 6 ||
          produto.servico === 8 ||
          produto.servico === 12, //Desabilitando campo do cartão benefício até que seja integrado: produto.servico === 5 || produto.servico === 6 || produto.servico === 7 || produto.servico === 8
        flagSaqueParcelado: produto.servico === 7,
        valorSaque: maskMoney(Number(produto.valorSaque).toFixed(2)),
        flagAberturaConta: false,
        bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
        margemDisponivel: produto.margemDisponivel,
        margemDisponivelCartao: produto.margemDisponivelCartao,
        margemDisponivelCartaoBeneficio:
          produto.margemDisponivelCartaoBeneficio,
        produtosSINDNAP: produto.produtosSINDNAP,
        tipoProduto: produto.tipoProduto,
        numeroContaInterna: produto.numeroContaInterna
      })
    })
    const produtosSindnapi = response.filter(
      (produto: ProdutosSimulacao) => produto.servico === 12
    )
    let produtoMaiorValor: ProdutosSimulacao | null = null
    let maiorValor = 0
    produtosSindnapi.map((produto: ProdutosSimulacao) => {
      if (
        (produto.produtosSINDNAP?.planos?.[0]?.valorPremioAux ?? 0) > maiorValor
      ) {
        maiorValor = produto.produtosSINDNAP?.planos[0].valorPremioAux ?? 0
        produtoMaiorValor = produto
      }
    })
    if (produtoMaiorValor) {
      produtosDisponiveis = produtosDisponiveis?.filter(
        (produto) => produto.servico !== 12
      )
      produtosDisponiveis.push(produtoMaiorValor)
    }
    yield put(simulacaoActions.setProdutosDisponiveis([...produtosDisponiveis]))
    yield put(
      simulacaoActions.setProdutosSelecionados([
        ...produtosDisponiveis.filter((produto) => {
          return !(
            (produto.servico === 5 || produto.servico === 8) &&
            produto.valorTotalFinanciado === 0
          )
        })
      ])
    )
    yield put(simulacaoActions.setJaSimulado())
  } catch (error) {
    handlerErrorSimulacao(
      error,
      'Houve um erro ao realizar a simulação, tente novamente mais tarde.'
    )
  } finally {
    yield put(simulacaoActions.setLoadingSimulacao(false))
    if (
      beneficios.find((beneficio) => beneficio.clientePerfil.tipoPerfil === 2)
    ) {
      yield put(simulacaoActions.solicitarSimulacaoFgtsSaga())
    }
  }
}

function* solicitarSimulacaoFgts(): SagaIterator {
  try {
    yield put(simulacaoActions.setLoadingSimulacao(true))
    yield call(simulacaoController.solicitarSimulacaoFgts)
    yield put(simulacaoActions.getParcelasFgtsSaga())
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Array.isArray(error?.messages)) {
      const { messages } = error
      for (const err of messages as Indicacao[]) {
        yield put(
          simulacaoActions.setInfosModalIndicacao({
            indicacao: err.indicacao,
            isOpen: true,
            metodoFgts: MetodosFgts.SolicitarSimulacao
          })
        )
      }
    } else {
      handlerErrorSimulacaoFgts(
        error,
        'Houve um erro ao solicitar a simulação do FGTS, tente novamente mais tarde.'
      )
    }
  } finally {
    yield put(simulacaoActions.setLoadingSimulacao(false))
  }
}

function* getParcelasFgts(): SagaIterator {
  const {
    cpf,
    produtosDisponiveis: produtosDisponiveisState,
    produtosSelecionados: produtosSelecionadosState
  }: SIMULACAO = yield select((state: ApplicationState) => state.simulacao)
  try {
    yield put(simulacaoActions.setLoadingSimulacao(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(simulacaoController.getParcelaFgts)
    const produtosDisponiveis = [...produtosDisponiveisState]
    const produtosSelecionados = [...produtosSelecionadosState]

    const iDisponivel = produtosDisponiveis.findIndex(
      (produto) => produto.servico === 9
    )
    const iSelecionado = produtosSelecionados.findIndex(
      (produto) => produto.servico === 9
    )
    if (iDisponivel !== -1) {
      produtosDisponiveis.splice(iDisponivel, 1)
    }
    if (iSelecionado !== -1) {
      produtosSelecionados.splice(iSelecionado, 1)
    }

    if (response?.data?.content && response?.data?.content.hoje) {
      const produtoFgts: ProdutosSimulacao = {
        id: 'fgts',
        perfil: 1,
        qtdeParcelas: Number(response?.data?.content?.quantidadeParcelas),
        servico: 9,
        valorTotalFinanciado: Number(response?.data?.content?.valorLiberado),
        parcelasFgtsItem: response?.data?.content?.parcelasFgtsItem,
        banco: 'BMG',
        codigoFator: '',
        codigoFormaEnvioTermo: null,
        codigoProduto: null,
        cpf: cpfFormatted,
        listProdutos: [],
        listParcelas: [],
        mensagemOperadora: null,
        matricula: '0',
        porcentagemFator: 0,
        valorDesejado: 0,
        valorLimite: 0,
        valorParcela: 0,
        valorSaqueMaximo: 0,
        valorSaqueMinimo: 0,
        flagSaqueAutorizado: false,
        flagSaqueParcelado: false,
        flagAberturaConta: false,
        bancoSimulacaoEnum: 0,
        margemDisponivel: 0,
        margemDisponivelCartao: 0,
        margemDisponivelCartaoBeneficio: 0,
        tipoProduto: 0,
        valorSaque: maskMoney(0),
        produtosSINDNAP: undefined,
        numeroContaInterna: null
      }
      produtosDisponiveis.push(produtoFgts)
      produtosSelecionados.push(produtoFgts)
    }
    yield put(simulacaoActions.setProdutosDisponiveis(produtosDisponiveis))
    yield put(simulacaoActions.setProdutosSelecionados(produtosSelecionados))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Array.isArray(error?.messages)) {
      const { messages } = error
      for (const err of messages as Indicacao[]) {
        yield put(
          simulacaoActions.setInfosModalIndicacao({
            indicacao: err.indicacao,
            isOpen: true,
            metodoFgts: MetodosFgts.SolicitarParcelas
          })
        )
      }
    } else {
      handlerErrorSimulacaoFgts(
        error,
        'Houve um erro ao obter a oferta do FGTS, tente novamente mais tarde.'
      )
    }
  } finally {
    yield put(simulacaoActions.setLoadingSimulacao(false))
  }
}

interface RecalculoPropostaProps extends GenericProps {
  servico: number
  parcelas: number
  valorTotal: number
  valorParcela: number
  tabela: number
  valor?: number
  numeroMatricula: string
  banco: number
}

function* recalculoProposta({
  servico,
  parcelas,
  valorTotal,
  valorParcela,
  tabela,
  numeroMatricula,
  banco
}: RecalculoPropostaProps): SagaIterator {
  const {
    cpf,
    produtosDisponiveis: produtosDisponiveisState,
    produtosSelecionados: produtosSelecionadosState
  }: SIMULACAO = yield select((state: ApplicationState) => state.simulacao)
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      simulacaoController.recalculoProposta,
      cpfFormatted,
      servico,
      parcelas,
      valorTotal || null,
      valorParcela || null,
      tabela,
      numeroMatricula,
      banco
    )
    const produtosDisponiveis = [...produtosDisponiveisState]
    const produtosSelecionados = [...produtosSelecionadosState]
    const indexDisponiveis = produtosDisponiveis.findIndex(
      (produto) =>
        produto.servico === response?.data?.content?.servico &&
        produto.matricula === response?.data?.content?.matricula
    )
    const indexSelecionados = produtosSelecionados.findIndex(
      (produto) =>
        produto.servico === response?.data?.content?.servico &&
        produto.matricula === response?.data?.content?.matricula
    )
    if (indexDisponiveis !== -1) {
      produtosDisponiveis[indexDisponiveis] = {
        ...produtosDisponiveis[indexDisponiveis],
        ...response?.data?.content,
        valorSaque: maskMoney(response?.data?.content?.valorSaque?.toFixed(2))
      }
    }
    if (indexSelecionados !== -1) {
      produtosSelecionados[indexSelecionados] = {
        ...produtosDisponiveis[indexDisponiveis],
        ...response?.data?.content,
        valorSaque: maskMoney(response?.data?.content?.valorSaque?.toFixed(2)),
        qtdeParcelas: response?.data?.content.qtdeParcelas
      }
    }
    yield put(simulacaoActions.setProdutosDisponiveis(produtosDisponiveis))
    yield put(simulacaoActions.setProdutosSelecionados(produtosSelecionados))
    if (response?.data?.messages?.length > 0) {
      handlerError(response?.data)
    }
    toast.success('Recálculo realizado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível recalcular!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* recalculoPropostaFgts({
  valor = 0,
  parcelas
}: RecalculoPropostaProps): SagaIterator {
  const {
    cpf,
    produtosDisponiveis: produtosDisponiveisState,
    produtosSelecionados: produtosSelecionadosState
  }: SIMULACAO = yield select((state: ApplicationState) => state.simulacao)
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    yield call(simulacaoController.recalculoPropostaFgts, valor, parcelas)
    const response = yield call(simulacaoController.getParcelaFgts)

    const produtosDisponiveis = [...produtosDisponiveisState]
    const produtosSelecionados = [...produtosSelecionadosState]

    if (response?.data?.content) {
      const produtoFgts: ProdutosSimulacao = {
        id: 'fgts',
        perfil: 1,
        qtdeParcelas: Number(response?.data?.content?.quantidadeParcelas),
        servico: 9,
        valorTotalFinanciado: Number(response?.data?.content?.valorLiberado),
        parcelasFgtsItem: response?.data?.content?.parcelasFgtsItem,
        banco: 'BMG',
        codigoFator: '',
        codigoFormaEnvioTermo: null,
        codigoProduto: null,
        cpf: cpfFormatted,
        listProdutos: [],
        listParcelas: [],
        mensagemOperadora: null,
        matricula: '0',
        porcentagemFator: 0,
        valorDesejado: 0,
        valorLimite: 0,
        valorParcela: 0,
        valorSaqueMaximo: 0,
        valorSaqueMinimo: 0,
        valorSaque: maskMoney(0),
        flagSaqueAutorizado: false,
        flagSaqueParcelado: false,
        flagAberturaConta: false,
        bancoSimulacaoEnum: 0,
        margemDisponivel: 0,
        margemDisponivelCartao: 0,
        margemDisponivelCartaoBeneficio: 0,
        tipoProduto: 0,
        produtosSINDNAP: undefined,
        numeroContaInterna: null
      }
      const indexDisponiveis = produtosDisponiveis.findIndex(
        (produto) => produto.servico === produtoFgts.servico
      )
      const indexSelecionados = produtosSelecionados.findIndex(
        (produto) => produto.servico === produtoFgts.servico
      )
      if (indexDisponiveis !== -1) {
        produtosDisponiveis[indexDisponiveis] = produtoFgts
      }
      if (indexSelecionados !== -1) {
        produtosSelecionados[indexSelecionados] = produtoFgts
      }
    }

    yield put(simulacaoActions.setProdutosDisponiveis(produtosDisponiveis))
    yield put(simulacaoActions.setProdutosSelecionados(produtosSelecionados))
    toast.success('Recálculo realizado com sucesso!')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Array.isArray(error?.messages)) {
      const { messages } = error
      for (const err of messages as Indicacao[]) {
        yield put(
          simulacaoActions.setInfosModalIndicacao({
            indicacao: err.indicacao,
            isOpen: true,
            metodoFgts: MetodosFgts.Recalculo
          })
        )
      }
    } else {
      handlerErrorSimulacaoFgts(
        error,
        'Houve um erro ao realizar o recálculo do FGTS, tente novamente mais tarde.'
      )
    }
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* validacaoContratacao(): SagaIterator {
  const { cpf, produtosSelecionados }: SIMULACAO = yield select(
    (state: ApplicationState) => state.simulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const matriculasContratacao = produtosSelecionados.map((produto) => {
      return produto.matricula
    })
    const response = yield call(
      simulacaoController.validacaoContratacao,
      cpfFormatted,
      [...new Set(matriculasContratacao)]
    )
    if (response?.data?.content.url) {
      stepCadastroComplementarRedirect(
        response?.data?.content.dadosComplementaresEnum
      )
    } else {
      yield put(
        simulacaoActions.toggleModalResumoContratacao(response?.data?.success)
      )
    }
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getResumoContratacao(): SagaIterator {
  const { cpf, produtosSelecionados }: SIMULACAO = yield select(
    (state: ApplicationState) => state.simulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const matriculasContratacao = produtosSelecionados.map((produto) => {
      return produto.matricula
    })
    const response = yield call(
      simulacaoController.getResumoContratacao,
      cpfFormatted,
      [...new Set(matriculasContratacao)]
    )

    const infosClienteResumo: InfosClienteResumo = {
      bairro: response?.data?.content?.bairro,
      cep: response?.data?.content?.cep,
      cidade: response?.data?.content?.cidade,
      cidadeNascimento: response?.data?.content?.cidadeNascimento,
      complemento: response?.data?.content?.complemento,
      cpf: maskCPF(response?.data?.content?.cpf),
      dataEmissao: moment(response?.data?.content?.dataEmissao).format(
        'DD/MM/YYYY'
      ),
      dataNascimento: moment(response?.data?.content?.dataNascimento).format(
        'DD/MM/YYYY'
      ),
      emissorIdentidade: response?.data?.content?.emissorIdentidade,
      estadoCivil: response?.data?.content?.estadoCivil,
      grauInstrucao: response?.data?.content?.grauInstrucao,
      matriculas: response?.data?.content?.matriculas.map(
        (matricula: MatriculaResumo) => {
          return {
            id: matricula.id,
            numeroMatricula: matricula.numeroMatricula,
            tipoPerfil: matricula.tipoPerfil,
            descricaoPerfil: matricula.descricaoPerfil,
            tipoPerfilId: matricula.tipoPerfilId,
            convenioId: matricula.convenioId,
            margemDisponivel: matricula.margemDisponivel,
            margemCartaoDisponivel: matricula.margemCartaoDisponivel,
            margemCartaoBeneficio: matricula.margemCartaoBeneficio,
            descricaoBanco: matricula.descricaoBanco,
            contaCorrenteId: matricula.contaCorrenteId,
            tipoContaId: matricula.tipoContaId,
            bancoDadosBancariosId: matricula.bancoDadosBancariosId,
            numeroAgencia: matricula.numeroAgencia,
            digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
            numeroConta: matricula.numeroConta,
            digitoVerificadorConta: matricula.digitoVerificadorConta
          }
        }
      ),
      nacionalidade: response?.data?.content?.nacionalidade,
      nome: response?.data?.content?.nome,
      nomeConjuge: response?.data?.content?.nomeConjuge,
      nomeMae: response?.data?.content?.nomeMae,
      nomePai: response?.data?.content?.nomePai,
      numeroEndereco: response?.data?.content?.numeroEndereco,
      numeroIdentidade: response?.data?.content?.numeroIdentidade,
      sexo: response?.data?.content?.sexo,
      uf: response?.data?.content?.uf,
      ufIdentidade: response?.data?.content?.ufIdentidade,
      ufNascimento: response?.data?.content?.ufNascimento,
      logradouro: response?.data?.content?.logradouro,
      numero: response?.data?.content?.numero,
      ddd: response?.data?.content?.ddd
    }

    yield put(simulacaoActions.setInfosClienteResumo(infosClienteResumo))
  } catch (error) {
    handlerError(error, 'Não foi possível carregar as informações do cliente!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* finalizacaoContratacao(): SagaIterator {
  const { cpf, produtosSelecionados: produtosSelecionadosState }: SIMULACAO =
    yield select((state: ApplicationState) => state.simulacao)
  yield put(loadingActions.setLoading(true))
  const cpfFormatted = removeMaskCPF(cpf)
  function* finalizaContratacoes(
    produto: ProdutosSimulacao
  ): Generator<
    CallEffect,
    void,
    AxiosResponse<ResponseWithMessages<ProdutosSimulacao[] | ReqFgts<null>>>
  > {
    try {
      switch (produto.servico) {
        case 1: {
          let response
          if (produto.bancoSimulacaoEnum === 1) {
            response = yield call(
              simulacaoController.finalizacaoEmprestimoBmg,
              {
                cpf: cpfFormatted,
                numeroMatricula: produto.matricula,
                quantidadeParcelas: produto.qtdeParcelas,
                valorParcela: produto.valorParcela,
                servico: produto.servico,
                numeroProduto: produto.codigoProduto || 0,
                bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
                codigoFormaEnvioTermo: produto.codigoFormaEnvioTermo ?? '',
                valorLiberado: produto.valorTotalFinanciado
              }
            )
          }
          if (produto.bancoSimulacaoEnum === 2) {
            response = yield call(
              simulacaoController.finalizacaoEmprestimoItau,
              {
                codigoSimulacao: produto.codigoSimulacao || 0,
                cpf: cpfFormatted,
                numeroMatricula: produto.matricula,
                quantidadeParcelas: produto.qtdeParcelas,
                valorParcela: produto.valorParcela,
                servico: produto.servico,
                bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
                valorLiberado: produto.valorTotalFinanciado
              }
            )
          }
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 5: {
          const response = yield call(
            simulacaoController.finalizacaoSaqueCartaoBmg,
            {
              cpf: cpfFormatted,
              numeroMatricula: produto.matricula,
              numeroContaInterna: produto.numeroContaInterna || 0,
              quantidadeParcelas: produto.qtdeParcelas,
              valorParcela: produto.valorParcela,
              valorSaque: produto.flagSaqueAutorizado
                ? Number(removeMaskMoney(produto.valorSaque))
                : produto.valorLimite,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagAberturaConta: produto.flagAberturaConta,
              servico: produto.servico,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 6: {
          const response = yield call(
            simulacaoController.finalizacaoCartaoCreditoBmg,
            {
              cpf: cpfFormatted,
              numeroMatricula: produto.matricula,
              valorSaque: produto.flagSaqueAutorizado
                ? Number(removeMaskMoney(produto.valorSaque))
                : produto.valorLimite,
              servico: produto.servico,
              flagAberturaConta: produto.flagAberturaConta,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 7: {
          const response = yield call(
            simulacaoController.finalizacaoCartaoBeneficioBmg,
            {
              cpf: cpfFormatted,
              numeroMatricula: produto.matricula,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagSaqueParcelado: produto.flagSaqueParcelado,
              quantidadeParcelas: produto.qtdeParcelas,
              valorParcela: produto.valorParcela,
              valorSaque:
                produto.flagSaqueAutorizado || produto.flagSaqueParcelado
                  ? Number(removeMaskMoney(produto.valorSaque))
                  : produto.valorLimite,
              servico: produto.servico,
              flagAberturaConta: produto.flagAberturaConta,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 8: {
          const response = yield call(
            simulacaoController.finalizacaoSaqueCartaoBmg,
            {
              cpf: cpfFormatted,
              numeroMatricula: produto.matricula,
              numeroContaInterna: produto.numeroContaInterna || 0,
              quantidadeParcelas: produto.qtdeParcelas,
              valorParcela: produto.valorParcela,
              valorSaque: produto.flagSaqueAutorizado
                ? Number(removeMaskMoney(produto.valorSaque))
                : produto.valorLimite,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagAberturaConta: produto.flagAberturaConta,
              servico: produto.servico,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 9: {
          yield call(simulacaoController.finalizacaoContratacaoFgts)
          toast.success('Contratação FGTS finalizada com sucesso!')
          break
        }
        case 12: {
          const response = yield call(
            simulacaoController.finalizacaoContratacaoSeguroSindinapiBmg,
            {
              cpf: cpfFormatted,
              numeroMatricula: produto.matricula,
              servico: produto.servico,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        default:
          break
      }
    } catch (error) {
      if (produto.servico !== 9) {
        handlerError(error, 'Não foi possível realizar a contratação!')
      } else {
        handlerErrorFgts(
          error,
          'Não foi possível realizar a contratação do FGTS!'
        )
      }
    }
  }
  yield all(
    produtosSelecionadosState?.map((produto) =>
      call(finalizaContratacoes, produto)
    )
  )
  yield put(simulacaoActions.toggleModalResumoContratacao(false))
  yield put(simulacaoActions.getPropostasSaga())
  yield put(loadingActions.setLoading(false))
  history.push('/propostas')
}

function* getPerfisResumo(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(simulacaoController.getPerfis)
    yield put(
      simulacaoActions.setOptionsSelect(response?.data?.content, 'perfis')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTiposContaResumo(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(simulacaoController.getTiposConta)
    yield put(
      simulacaoActions.setOptionsSelect(response?.data?.content, 'tiposConta')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getBancosResumo(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(simulacaoController.getBancos)
    yield put(
      simulacaoActions.setOptionsSelect(response?.data?.content, 'bancos')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getConveniosResumo(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(simulacaoController.getConvenios)
    yield put(
      simulacaoActions.setOptionsSelect(response?.data?.content, 'convenios')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* inserirSolicitacoesIn100(): SagaIterator {
  const { validacaoFields }: SIMULACAO = yield select(
    (state: ApplicationState) => state.simulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(simulacaoController.inserirSolicitacaoIn100, {
      ddd: validacaoFields.celular.substring(1, 3),
      telefone: validacaoFields.celular.substring(5).replace('-', ''),
      matricula: validacaoFields.matricula
    })
    toast.success('Enviado código para o seu celular')
  } catch (error) {
    handlerError(error, 'Não foi possível enviar a solicitação')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* validarTokenIn100(): SagaIterator {
  const { validacaoFields }: SIMULACAO = yield select(
    (state: ApplicationState) => state.simulacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(simulacaoController.validarTokenIn100, {
      token: validacaoFields.codigo,
      matricula: validacaoFields.matricula
    })
    yield put(simulacaoActions.toggleModalIN100(false))
    toast.success('Código validado com sucesso')
    yield put(simulacaoActions.validaStatusIN100Saga())
  } catch (error) {
    handlerError(error, 'Não foi possível validar o código')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetClienteMatriculaCpf() {
  yield takeEvery(TYPES.GET_CLIENTE_MATRICULA_CPF_SAGA, getClienteMatriculaCpf)
}

export function* watchValidaStatusIN100() {
  yield takeEvery(TYPES.VALIDA_STATUS_IN100_SAGA, validaStatusIN100)
}

export function* watchGetPropostas() {
  yield takeEvery(TYPES.GET_PROPOSTAS_SAGA, getPropostas)
}

export function* watchSolicitarSimulacaoFgts() {
  yield takeEvery(TYPES.SOLICITAR_SIMULACAO_FGTS_SAGA, solicitarSimulacaoFgts)
}

export function* watchGetParcelasFgts() {
  yield takeEvery(TYPES.GET_PARCELAS_FGTS_SAGA, getParcelasFgts)
}

export function* watchRecalculoProposta() {
  yield takeEvery(TYPES.RECALCULO_PROPOSTA_SAGA, recalculoProposta)
}

export function* watchRecalculoPropostaFgts() {
  yield takeEvery(TYPES.RECALCULO_PROPOSTA_FGTS_SAGA, recalculoPropostaFgts)
}

export function* watchValidacaoContratacao() {
  yield takeEvery(TYPES.VALIDACAO_CONTRATACAO_SAGA, validacaoContratacao)
}

export function* watchGetResumoContratacao() {
  yield takeEvery(TYPES.GET_RESUMO_CONTRATACAO_SAGA, getResumoContratacao)
}

export function* watchFinalizacaoContratacao() {
  yield takeEvery(TYPES.FINALIZACAO_CONTRATACAO_SAGA, finalizacaoContratacao)
}

export function* watchGetPerfisResumo() {
  yield takeEvery(TYPES.GET_PERFIS_RESUMO_SAGA, getPerfisResumo)
}

export function* watchGetTiposContaResumo() {
  yield takeEvery(TYPES.GET_TIPOS_CONTA_RESUMO_SAGA, getTiposContaResumo)
}

export function* watchGetBancosResumo() {
  yield takeEvery(TYPES.GET_BANCOS_RESUMO_SAGA, getBancosResumo)
}

export function* watchGetConveniosResumo() {
  yield takeEvery(TYPES.GET_CONVENIOS_RESUMO_SAGA, getConveniosResumo)
}

export function* watchInserirSolicitacoesIn100() {
  yield takeEvery(
    TYPES.INSERIR_SOLICITACOES_IN100_SAGA,
    inserirSolicitacoesIn100
  )
}

export function* watchValidarTokenIn100() {
  yield takeEvery(TYPES.VALIDAR_TOKEN_IN100_SAGA, validarTokenIn100)
}

function* simulacao() {
  yield all([
    fork(watchGetClienteMatriculaCpf),
    fork(watchValidaStatusIN100),
    fork(watchGetPropostas),
    fork(watchSolicitarSimulacaoFgts),
    fork(watchGetParcelasFgts),
    fork(watchRecalculoProposta),
    fork(watchRecalculoPropostaFgts),
    fork(watchValidacaoContratacao),
    fork(watchGetResumoContratacao),
    fork(watchFinalizacaoContratacao),
    fork(watchGetPerfisResumo),
    fork(watchGetTiposContaResumo),
    fork(watchGetBancosResumo),
    fork(watchGetConveniosResumo),
    fork(watchInserirSolicitacoesIn100),
    fork(watchValidarTokenIn100)
  ])
}

export default simulacao
