import React, { useEffect } from 'react'
import dadosComplementares from 'assets/images/cadastros/dados-complementares.png'
import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import { STEPS_CADASTRO_COMPLEMENTAR } from 'views/cadastros/constants/constants'
import Steps from 'components/Steps'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { CustomButton } from 'styles/global'
import { DADOS_COMPLEMENTARES_INFOS_PESSOAL } from 'store/modules/cadastros/dadosComplementaresInfoPessoal/types'
import { ApplicationState } from 'store'
import * as dadosComplementaresInfosPessoalActions from 'store/modules/cadastros/dadosComplementaresInfoPessoal/actions'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import CustomSelect from 'components/Select'
import { toast } from 'react-toastify'
import { schema } from './constants/schema'
import * as yup from 'yup'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import { IoMdArrowBack } from 'react-icons/io'

const DadosComplementaresInfoPessoal = () => {
  const { formulario, invalidFormulario, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, DADOS_COMPLEMENTARES_INFOS_PESSOAL>(
      (state) => state.dadosComplementaresInfosPessoal
    )

  const { dispatch } = useRedux()

  type FormularioField = keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario']
  const onChange = <T extends FormularioField>(
    value: T extends keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario']
      ? DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario'][T]
      : never,
    field: T
  ) => {
    if (field === 'ufNascimento') {
      dispatch(
        dadosComplementaresInfosPessoalActions.setFormularioField(
          '',
          'cidadeNascimento'
        )
      )
    }
    dispatch(
      dadosComplementaresInfosPessoalActions.setFormularioField(value, field)
    )
  }

  useEffect(() => {
    dispatch(dadosComplementaresInfosPessoalActions.getEscolaridadesSaga())
    dispatch(dadosComplementaresInfosPessoalActions.getEstadosCivisSaga())
    dispatch(dadosComplementaresInfosPessoalActions.getGenerosSaga())
    dispatch(dadosComplementaresInfosPessoalActions.getProfissoesSaga())
    dispatch(dadosComplementaresInfosPessoalActions.getUfsNascimentoSaga())
    dispatch(dadosComplementaresInfosPessoalActions.getCpfSaga())
  }, [dispatch])

  useEffect(() => {
    if (formulario.ufNascimento) {
      dispatch(
        dadosComplementaresInfosPessoalActions.getCidadesNascimentoSaga(
          formulario.ufNascimento
        )
      )
    }
  }, [dispatch, formulario.ufNascimento])

  const onSave = () => {
    dispatch(dadosComplementaresInfosPessoalActions.cleanInvalidsFormulario())
    schema
      .validate(
        {
          sexo: formulario.sexo,
          estadoCivil: formulario.estadoCivil,
          nomeConjuge: formulario.nomeConjuge,
          profissaoId: formulario.profissaoId,
          nacionalidade: formulario.nacionalidade,
          escolaridadeId: formulario.escolaridadeId,
          ufNascimento: formulario.ufNascimento,
          cidadeNascimento: formulario.cidadeNascimento
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        dispatch(dadosComplementaresInfosPessoalActions.salvarCadastroSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            dadosComplementaresInfosPessoalActions.setInvalidFormularioField(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['invalidFormulario']
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <SimpleCadastro banner={dadosComplementares}>
      <div className="container-fluid d-flex flex-column row-gap-20">
        <Steps steps={STEPS_CADASTRO_COMPLEMENTAR} currentStep={2} />
        <Row>
          <Col>
            <label className="label-12">Gênero*</label>
            <CustomSelect
              isLoading={loadingSelects.generos}
              options={optionsSelects.generos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'sexo')
              }}
              value={formulario.sexo}
              accessorLabel="descricao"
              accessorValue="value"
              invalid={invalidFormulario.sexo.invalid}
              errorMessage={invalidFormulario.sexo.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Estado civil*</label>
            <CustomSelect
              isLoading={loadingSelects.estadosCivis}
              options={optionsSelects.estadosCivis}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'estadoCivil')
              }}
              value={formulario.estadoCivil}
              accessorLabel="descricao"
              accessorValue="value"
              invalid={invalidFormulario.estadoCivil.invalid}
              errorMessage={invalidFormulario.estadoCivil.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">
              Nome do Cônjuge
              {(formulario.estadoCivil === 'CASADO' ||
                formulario.estadoCivil === 'MARITAL') &&
                '*'}
            </label>
            <InputCustom
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nomeConjuge')
              }}
              value={formulario.nomeConjuge}
              invalid={invalidFormulario.nomeConjuge.invalid}
              errorMessage={invalidFormulario.nomeConjuge.message}
              maxLength={100}
              infoMessage="Obrigatório se o estado civil for casado ou marital"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Profissão*</label>
            <CustomSelect
              isLoading={loadingSelects.profissoes}
              options={optionsSelects.profissoes}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'profissaoId')
              }}
              value={formulario.profissaoId}
              accessorLabel="titulo"
              accessorValue="id"
              invalid={invalidFormulario.profissaoId.invalid}
              errorMessage={invalidFormulario.profissaoId.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Escolaridade*</label>
            <CustomSelect
              isLoading={loadingSelects.escolaridades}
              options={optionsSelects.escolaridades}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'escolaridadeId')
              }}
              value={formulario.escolaridadeId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalidFormulario.escolaridadeId.invalid}
              errorMessage={invalidFormulario.escolaridadeId.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">UF nascimento*</label>
            <CustomSelect
              isLoading={loadingSelects.ufsNascimento}
              options={optionsSelects.ufsNascimento}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'ufNascimento')
              }}
              value={formulario.ufNascimento}
              accessorLabel="nome"
              accessorValue="sigla"
              invalid={invalidFormulario.ufNascimento.invalid}
              errorMessage={invalidFormulario.ufNascimento.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Cidade de nascimento*</label>
            <CustomSelect
              isLoading={loadingSelects.cidadesNascimento}
              options={optionsSelects.cidadesNascimento}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'cidadeNascimento')
              }}
              value={formulario.cidadeNascimento}
              accessorLabel="nome"
              accessorValue="nome"
              invalid={invalidFormulario.cidadeNascimento.invalid}
              errorMessage={invalidFormulario.cidadeNascimento.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="90%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                stepCadastroComplementarRedirect(1)
              }}
            >
              VOLTAR
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="90%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                onSave()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleCadastro>
  )
}

export default DadosComplementaresInfoPessoal
