export enum TYPES {
  SET_LOGIN = '@@login/SET_LOGIN',
  CLEAN_LOGIN = '@@login/CLEAN_LOGIN',
  SET_LOADING = '@@login/SET_LOADING',
  SET_RECUPERACAO = '@@login/SET_RECUPERACAO',
  LOGIN_SAGA = '@@login/LOGIN_SAGA',
  LOGOUT_SAGA = '@@login/LOGOUT_SAGA',
  RECUPERAR_ACESSO_SAGA = '@@login/RECUPERAR_ACESSO_SAGA',
  VALIDA_GUID_RECUPERACAO_SAGA = '@@login/VALIDA_GUID_RECUPERACAO_SAGA',
  REDEFINIR_SENHA_SAGA = '@@login/REDEFINIR_SENHA_SAGA',
  GET_STATUS_LEAD_SAGA = '@@login/GET_STATUS_LEAD_SAGA'
}

export interface FormLogin {
  email: string
  senha: string
  lembrar: boolean
}

export interface FormRecuperacao {
  senha: string
  confirmacaoSenha: string
}

export interface LOGIN {
  login: FormLogin
  recuperacao: FormRecuperacao
  loading: boolean
}
