import { CADASTRO_INICIAL } from 'store/modules/cadastros/cadastroInicial/types'
import { validaCpf } from 'util/validations'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schemaFormulario: yup.SchemaOf<CADASTRO_INICIAL['formulario']> =
  yup.object().shape({
    nome: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .min(3)
      .max(100)
      .matches(/^[^0-9]*$/, 'O nome não pode conter números') // Adiciona a validação para não aceitar números
      .required()
      .label('Nome'),
    cpf: yup
      .string()
      .test('test-invalid-cpf', 'cpf inválido', (cpf) => validaCpf(cpf || ''))
      .required()
      .label('CPF'),
    dataNascimento: yup.string().required().label('Data de nascimento'),
    email: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .email()
      .required()
      .label('Email'),
    celular: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .min(11)
      .required()
      .label('Celular'),
    politicaCompartilhamento: yup
      .boolean()
      .required(({ label }) => `${label} devem ser aceitas`)
      .oneOf([true], ({ label }) => `${label} devem ser aceitas`)
      .label('Políticas de compartilhamento')
  })

export const schemaModalSenha: yup.SchemaOf<CADASTRO_INICIAL['modalSenha']> =
  yup.object().shape({
    senha: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .min(6, 'A senha deve ter pelo menos 6 caracteres')
      .required()
      .label('Senha'),
    confirmacaoSenha: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .oneOf([yup.ref('senha'), null], 'As senhas devem ser iguais')
      .required()
      .label('Confirmação senha')
  })
