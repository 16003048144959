import { ProdutosSimulacao } from 'models/types'
import { Reducer } from 'redux'
import { SIMULACAO, TYPES } from './types'

const INITIAL_STATE: SIMULACAO = {
  nomeCompleto: '',
  cpf: '',
  beneficios: [],
  somatorioTotalProdutos: 0,
  somatorioResumo: 0,
  produtosDisponiveis: [],
  produtoSelected: {
    servico: 0,
    matricula: ''
  },
  infosClienteResumo: {
    nome: '',
    cpf: '',
    dataNascimento: '',
    numeroIdentidade: '',
    emissorIdentidade: '',
    ufIdentidade: '',
    dataEmissao: '',
    nomePai: '',
    nomeMae: '',
    cep: '',
    logradouro: '',
    numeroEndereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    complemento: '',
    numero: '',
    ddd: '',
    sexo: '',
    estadoCivil: '',
    nomeConjuge: '',
    cidadeNascimento: '',
    ufNascimento: '',
    nacionalidade: '',
    grauInstrucao: '',
    matriculas: []
  },
  detalhamentoIsOpen: false,
  modalResumoContratacao: false,
  loadingSimulacao: false,
  invalidContratacao: false,
  produtosSelecionados: [],
  optionsSelect: {
    perfis: [],
    bancos: [],
    convenios: [],
    tiposConta: []
  },
  loadingSelect: {
    perfis: false,
    bancos: false,
    convenios: false,
    tiposConta: false
  },
  perfis: [],
  modalIndicacaoFgts: {
    isOpen: false,
    indicacao: null,
    metodoFgts: null
  },
  validacaoFields: {
    celular: '',
    codigo: '',
    matricula: ''
  },
  validacaoFieldsInvalids: {
    celular: {
      invalid: false,
      message: ''
    },
    codigo: {
      invalid: false,
      message: ''
    },
    matricula: {
      invalid: false,
      message: ''
    }
  },
  modalIN100: false,
  matriculasValidacao: [],
  jaSimulado: false
}

const reducer: Reducer<SIMULACAO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_NOME_COMPLETO:
      return {
        ...state,
        nomeCompleto: action.value
      }
    case TYPES.SET_CPF:
      return {
        ...state,
        cpf: action.value
      }
    case TYPES.SET_BENEFICIOS:
      return {
        ...state,
        beneficios: action.value
      }
    case TYPES.TOGGLE_DETALHAMENTO:
      return {
        ...state,
        detalhamentoIsOpen: !state.detalhamentoIsOpen,
        produtoSelected: INITIAL_STATE.produtoSelected
      }
    case TYPES.TOGGLE_MODAL_RESUMO_CONTRATACAO:
      return {
        ...state,
        modalResumoContratacao: action.value
      }
    case TYPES.SET_INFOS_CLIENTE_RESUMO:
      return {
        ...state,
        infosClienteResumo: action.value
      }
    case TYPES.SET_LOADING_SIMULACAO:
      return { ...state, loadingSimulacao: action.value }
    case TYPES.SET_INFOS_CARTAO_CONSIG: {
      const produtosDisponiveis = [...state.produtosDisponiveis]
      const produtosSelecionados = [...state.produtosSelecionados]
      const iDisponivel = produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      const iSelecionado = produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      const iDisponivelBen = produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      const iSelecionadoBen = produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        produtosDisponiveis[iDisponivel] = {
          ...produtosDisponiveis[iDisponivel],
          [action.field]: action.value
        }
      }
      if (iSelecionado !== -1) {
        produtosSelecionados[iSelecionado] = {
          ...produtosSelecionados[iSelecionado],
          [action.field]: action.value
        }
      }
      if (
        iDisponivelBen !== -1 &&
        (action.field as keyof ProdutosSimulacao) === 'flagAberturaConta'
      ) {
        produtosDisponiveis[iDisponivelBen] = {
          ...produtosDisponiveis[iDisponivelBen],
          flagAberturaConta: false
        }
      }
      if (
        (iSelecionadoBen !== -1 &&
          (action.field as keyof ProdutosSimulacao)) === 'flagAberturaConta'
      ) {
        produtosSelecionados[iSelecionadoBen] = {
          ...produtosSelecionados[iSelecionadoBen],
          flagAberturaConta: false
        }
      }
      return {
        ...state,
        produtosDisponiveis: produtosDisponiveis,
        produtosSelecionados: produtosSelecionados
      }
    }
    case TYPES.SET_INFOS_CARTAO_BENEFICIO: {
      const produtosDisponiveis = [...state.produtosDisponiveis]
      const produtosSelecionados = [...state.produtosSelecionados]
      const iDisponivel = produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      const iSelecionado = produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 7 && produto.matricula === action.matricula
      )
      const iDisponivelConsig = produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      const iSelecionadoConsig = produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 6 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        produtosDisponiveis[iDisponivel] = {
          ...produtosDisponiveis[iDisponivel],
          [action.field]: action.value
        }
      }
      if (iSelecionado !== -1) {
        produtosSelecionados[iSelecionado] = {
          ...produtosSelecionados[iSelecionado],
          [action.field]: action.value
        }
      }
      if (
        iDisponivelConsig !== -1 &&
        (action.field as keyof ProdutosSimulacao) === 'flagAberturaConta'
      ) {
        produtosDisponiveis[iDisponivelConsig] = {
          ...produtosDisponiveis[iDisponivelConsig],
          flagAberturaConta: false
        }
      }
      if (
        (iSelecionadoConsig !== -1 &&
          (action.field as keyof ProdutosSimulacao)) === 'flagAberturaConta'
      ) {
        produtosSelecionados[iSelecionadoConsig] = {
          ...produtosSelecionados[iSelecionadoConsig],
          flagAberturaConta: false
        }
      }
      return {
        ...state,
        produtosDisponiveis: produtosDisponiveis,
        produtosSelecionados: produtosSelecionados
      }
    }
    case TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO: {
      const produtosDisponiveis = [...state.produtosDisponiveis]
      const produtosSelecionados = [...state.produtosSelecionados]
      const iDisponivel = produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 5 && produto.matricula === action.matricula
      )
      const iSelecionado = produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 5 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        produtosDisponiveis[iDisponivel] = {
          ...produtosDisponiveis[iDisponivel],
          [action.field]: action.value
        }
      }
      if (iSelecionado !== -1) {
        produtosSelecionados[iSelecionado] = {
          ...produtosSelecionados[iSelecionado],
          [action.field]: action.value
        }
      }
      return {
        ...state,
        produtosDisponiveis: produtosDisponiveis,
        produtosSelecionados: produtosSelecionados
      }
    }
    case TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO: {
      const produtosDisponiveis = [...state.produtosDisponiveis]
      const produtosSelecionados = [...state.produtosSelecionados]
      const iDisponivel = produtosDisponiveis.findIndex(
        (produto) =>
          produto.servico === 8 && produto.matricula === action.matricula
      )
      const iSelecionado = produtosSelecionados.findIndex(
        (produto) =>
          produto.servico === 8 && produto.matricula === action.matricula
      )
      if (iDisponivel !== -1) {
        produtosDisponiveis[iDisponivel] = {
          ...produtosDisponiveis[iDisponivel],
          [action.field]: action.value
        }
      }
      if (iSelecionado !== -1) {
        produtosSelecionados[iSelecionado] = {
          ...produtosSelecionados[iSelecionado],
          [action.field]: action.value
        }
      }
      return {
        ...state,
        produtosDisponiveis: produtosDisponiveis,
        produtosSelecionados: produtosSelecionados
      }
    }
    case TYPES.SET_INVALID_CONTRATACAO:
      return { ...state, invalidContratacao: action.value }
    case TYPES.SELECT_PRODUTO:
      return { ...state, produtoSelected: action.value }
    case TYPES.SET_PRODUTOS_DISPONIVEIS:
      return { ...state, produtosDisponiveis: action.value }
    case TYPES.SET_PRODUTOS_SELECIONADOS:
      return { ...state, produtosSelecionados: action.value }
    case TYPES.SET_SOMATORIO_TOTAL_PRODUTOS:
      return { ...state, somatorioTotalProdutos: action.value }
    case TYPES.SET_SOMATORIO_RESUMO:
      return { ...state, somatorioResumo: action.value }
    case TYPES.SET_INFOS_MODAL_INDICACAO:
      return {
        ...state,
        modalIndicacaoFgts: action.value
      }
    case TYPES.CLEAN_INFOS_MODAL_INDICACAO:
      return {
        ...state,
        modalIndicacaoFgts: INITIAL_STATE.modalIndicacaoFgts
      }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelect: {
          ...state.optionsSelect,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_SELECTS:
      return {
        ...state,
        loadingSelect: {
          ...state.loadingSelect,
          [action.field]: action.value
        }
      }
    case TYPES.SET_VALIDACAO_FIELDS:
      return {
        ...state,
        validacaoFields: {
          ...state.validacaoFields,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_VALIDACAO_FIELDS:
      return {
        ...state,
        validacaoFields: INITIAL_STATE.validacaoFields
      }
    case TYPES.SET_VALIDACAO_FIELDS_INVALIDS:
      return {
        ...state,
        validacaoFieldsInvalids: {
          ...state.validacaoFieldsInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_VALIDACAO_FIELDS_INVALIDS:
      return {
        ...state,
        validacaoFieldsInvalids: INITIAL_STATE.validacaoFieldsInvalids
      }
    case TYPES.TOGGLE_MODAL_IN100:
      return {
        ...state,
        modalIN100: action.value
      }
    case TYPES.SET_MATRICULAS_VALIDACAO:
      return {
        ...state,
        matriculasValidacao: action.value
      }
    case TYPES.SET_JA_SIMULADO:
      return {
        ...state,
        jaSimulado: true
      }
    default:
      return state
  }
}

export default reducer
